import React from "react";

const newsletterData = {
  bannerImg: "assets/img/subscribe-bg.jpg",
  iconImg: "assets/img/icons/subscribe.svg",
  title: "Subscribe to Newsletter",
  description: "Just for you, exciting  news updates.",
  inputPlaceholder: "Enter Email Address",
  buttonText: "Subscribe",
};

const Newslettersport = () => {
  return (
    <section className="section newsletter-sport">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="subscribe-style aos" data-aos="fade-up">
              <div className="banner-blk">
                <img
                  src={newsletterData.bannerImg}
                  className="img-fluid"
                  alt="Subscribe"
                />
              </div>
              <div className="banner-info">
                <img
                  src={newsletterData.iconImg}
                  className="img-fluid"
                  alt="Subscribe"
                />
                <h2>{newsletterData.title}</h2>
                <p>{newsletterData.description}</p>
                <div className="subscribe-blk bg-white">
                  <div className="input-group align-items-center">
                    <i className="feather-mail"></i>
                    <input
                      type="email"
                      className="form-control"
                      placeholder={newsletterData.inputPlaceholder}
                      aria-label="email"
                    />
                    <div className="subscribe-btn-grp">
                      <input
                        type="submit"
                        className="btn btn-secondary"
                        value={newsletterData.buttonText}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newslettersport;
