import Featuredvenue from "./Featured-venue";
import Header from "./Header";
import Servicesection from "./Service-section";
import Worksection from "./Work-section";
import Featuredsection from "./Featured-section";
import Groupcoaching from "./Group-coaching";
import Earnmoney from "./Earn-money";
import Bestservice from "./Best-services";
import Ourtestimonial from "./Our-testimonials";
import Featuredplan from "./Featured-plan";
// import Latestnews from "./Latest-news";
import Newslettersport from "./Newsletter-sport";
import Hero from "./Hero-section";
import Footer from "../Footer/Footer";

const Home = () => {
  return (
    <>
      <Header></Header>
      <Hero></Hero>
      <Featuredvenue></Featuredvenue>
      <Featuredsection></Featuredsection>
      <Worksection></Worksection>
      <Servicesection></Servicesection>
      <Groupcoaching></Groupcoaching>
      <Earnmoney></Earnmoney>
      <Bestservice></Bestservice>
      {/* <Ourtestimonial></Ourtestimonial> */}
      <Featuredplan></Featuredplan>
      {/* <Latestnews></Latestnews> */}
      <Newslettersport></Newslettersport>
      <Footer></Footer>
    </>
  );
};
export default Home;
