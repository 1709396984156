import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faPinterestP,
} from "@fortawesome/free-brands-svg-icons";

const teamMembers = [
  {
    name: "Asis Jovin",
    role: "FOUNDER & CEO",
    imgSrc: "assets/ourteam/founder.jpeg",
    description:
      "Results-driven CEO, Mr. Asis Jovin, fosters innovation and drives growth with proven success. Her strategic vision, collaborative leadership, and financial acumen consistently deliver excellence, value, and strong stakeholder relationships.",
    socialLinks: {
      instagram:
        "https://www.instagram.com/a_s_i_s_f_e_r_n_a_n_d_o?igsh=eTh1d2ljZmpxemkz",
      //   twitter: "#",
    },
  },
  {
    name: "Selva Sri",
    role: "CO-FOUNDER & COO",
    imgSrc: "assets/ourteam/Cofounder.jpeg",
    description:
      "Mr. Selva Sri, our Co-Founder & COO, drives impactful business strategies and operational excellence. With expertise in strategic planning and leadership, he ensures our company's growth and success while fostering innovation and efficiency.",
    socialLinks: {
      //   instagram: "#",
      //   twitter: "#",
    },
  },
];

const Ourteam = () => {
  const getSocialIcon = (platform) => {
    switch (platform) {
      case "instagram":
        return faInstagram;
      case "twitter":
        return faTwitter;
      case "pinterest":
        return faPinterestP;
      default:
        return null;
    }
  };

  // Slice the array to include only the first 3 team members
  const displayedMembers = teamMembers.slice(0, 3);

  return (
    <>
      <div className="content aboutus-info">
        <div className="container">
          <section className="section ourteam dull-bg">
            <div className="container">
              <div className="section-heading">
                <h2>
                  Our <span>Team</span>
                </h2>
                <p className="sub-title">
                  Our team united by passion, driven by excellence.
                </p>
              </div>
              <div className="featured-slider-group p-0">
                <div className="owl-carousel team-slider owl-theme">
                  {displayedMembers.map((member, index) => (
                    <div className="team-item" key={index}>
                      <div className="info text-center">
                        <div className="wrap">
                          <div className="profile-pic">
                            <img
                              src={member.imgSrc}
                              className="img-fluid"
                              alt={`Team-${index + 1}`}
                            />
                          </div>
                          <div className="short-info">
                            <h4>{member.name}</h4>
                            <h6>{member.role}</h6>
                          </div>
                        </div>
                        <div className="more">
                          <div className="short-info">
                            <h4>{member.name}</h4>
                            <h6>{member.role}</h6>
                          </div>
                          <p>{member.description}</p>
                          <ul className="social-medias d-inline-flex">
                            {Object.keys(member.socialLinks).map(
                              (platform, pIndex) => (
                                <li key={pIndex} className={platform}>
                                  <a
                                    href={member.socialLinks[platform]}
                                    aria-label={platform}
                                  >
                                    <FontAwesomeIcon
                                      icon={getSocialIcon(platform)}
                                    />
                                  </a>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Ourteam;
