import React from "react";

const contentData = [
  {
    title: "Your Vision",
    content: [
      "We envision a vibrant community of turf enthusiasts, driven by convenience and excellence in sports booking. Our platform is designed to transform how you connect with your favorite sports venues, offering a seamless experience to discover, book, and enjoy turf facilities. By harnessing cutting-edge technology and a user-centric approach, we aim to make every game memorable and every booking effortless. Join us in shaping the future of turf sports, where every play is a step towards a more active, connected, and exciting community!",
    ],
    className: "col-12 col-sm-12 col-md-12 col-lg-8",
  },
  {
    title: "Our Mission",
    content: [
      " Our vision is to revolutionize turf booking by offering a user-friendly platform that simplifies the process, ensures transparency, and enhances engagement. Join us as we pave the way for a future where booking your next game is just as exciting as the game itself!",
    ],
    className: "col-12 col-sm-12 col-md-12 col-lg-4",
    isMissionBg: true,
  },
];

const Vision = () => {
  return (
    <>
      <div className="content aboutus-info">
        <div className="container">
          <div className="vision-mission">
            <div className="row">
              {contentData.map((section, index) => (
                <div key={index} className={section.className}>
                  <div className={section.isMissionBg ? "mission-bg" : ""}>
                    <h2>{section.title}</h2>
                    {section.content.map((paragraph, pIndex) => (
                      <p key={pIndex}>{paragraph}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vision;
