import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Profileedit.css";

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    address: "",
    state: "",
    city: "",
    zipcode: "",
    customUserId: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstname) newErrors.firstname = "firstname is required";
    if (!formData.lastname) newErrors.lastname = "lastname number is required";
    if (!formData.zipcode) newErrors.zipcode = "Zipcode is required";
    return newErrors;
  };

  const handleSaveChanges = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const response = await axios.post(
        "https:/api.dremerz.net/api/lawnlinksaveprofile",
        formData
      );
      if (response.status === 200) {
        setSuccessMessage("Profile saved successfully!");
      } else {
        setErrorMessage("Failed to save profile. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleResetProfile = () => {
    setFormData({
      firstname: "",
      lastname: "",
      address: "",
      state: "",
      city: "",
      zipcode: "",
    });
    setErrors({});
    setSuccessMessage("");
    setErrorMessage("");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    var user = JSON.parse(sessionStorage.getItem("user"));
    setUserData(user);
    setFormData((prevData) => ({
      ...prevData,
      customUserId: user.custom_user_id,
    }));
  }, []);
  return (
    <div className="content">
      <div className="content court-bg new-profile-edit">
        <div className="container">
          <div className="coach-court-list profile-court-list">
            <ul className="nav">
              <li>
                <a className="active" href="/Profileedit">
                  My Profile
                </a>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="section-heading aos" data-aos="fade-up">
                <h2>
                  <span>Edit Profile</span>
                </h2>
              </div>

              {/* Image Upload */}
              <div className="file-upload-text">
                <div className="file-upload">
                  <img
                    src="assets/img/icons/img-icon.svg"
                    className="img-fluid"
                    alt="Upload"
                  />
                  <p>Upload Photo</p>
                  <input
                    type="file"
                    id="file-input"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>
                {file && (
                  <img
                    src={file}
                    alt="Preview"
                    style={{ width: "200px", height: "200px" }}
                  />
                )}
                <h5>
                  Upload an image with a minimum size of 150 * 150 pixels (JPG,
                  PNG, SVG).
                </h5>
              </div>

              {/* Profile Form */}
              <div className="card">
                <form>
                  <div className="row">
                    {["firstname", "lastname"].map((field) => (
                      <div className="col-lg-4 col-md-6" key={field}>
                        <div className="input-space">
                          <label className="form-label">
                            {field.charAt(0).toUpperCase() + field.slice(1)}
                          </label>
                          <input
                            type={field === "email" ? "email" : "text"}
                            className="form-control"
                            id={field}
                            placeholder={`Enter ${
                              field.charAt(0).toUpperCase() + field.slice(1)
                            }`}
                            value={formData[field]}
                            onChange={handleInputChange}
                          />
                          {errors[field] && (
                            <div className="invalid-feedback">
                              {errors[field]}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}

                    {/* Address fields */}
                    {["address", "state", "city", "zipcode"].map((field) => (
                      <div
                        className={`col-lg-${
                          field === "zipcode" ? "4 col-md-6" : "12 col-md-12"
                        }`}
                        key={field}
                      >
                        <div className="input-space">
                          <label className="form-label">
                            {field.charAt(0).toUpperCase() + field.slice(1)}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id={field}
                            placeholder={`Enter ${
                              field.charAt(0).toUpperCase() + field.slice(1)
                            }`}
                            value={formData[field]}
                            onChange={handleInputChange}
                          />
                          {errors[field] && (
                            <div className="invalid-feedback">
                              {errors[field]}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </form>
              </div>

              {/* Profile Actions */}
              <div className="save-changes text-end">
                <button
                  type="button"
                  className="btn btn-primary reset-profile"
                  onClick={handleResetProfile}
                >
                  Reset
                </button>
                <button
                  type="button"
                  className="btn btn-secondary save-profile"
                  onClick={handleSaveChanges}
                >
                  {loading ? "Saving..." : "Save Changes"}
                </button>
              </div>

              {successMessage && (
                <div className="alert alert-success">{successMessage}</div>
              )}
              {errorMessage && (
                <div className="alert alert-danger">{errorMessage}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
