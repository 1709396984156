import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";
import "./Booknow.css";
import Dateandtimepicker from "./Dateandtimepicker";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

const bookingSteps = [
  { step: 1, label: "Book a Court" },
  { step: 2, label: "Order Confirmation" },
  { step: 3, label: "Payment" },
];

const BookingPage = () => {
  const [bookingDetails, setBookingDetails] = useState(null);
  const [courts, setCourts] = useState([]);
  const navigate = useNavigate();
  const courts1 = "";

  useEffect(() => {
    // Mock data fetching from backend
    const fetchData = async () => {
      const bookingDetailsData = {
        TurfName: "Standard Synthetic Court 1",
        date: "27, April 2023",
        startTime: "01:00 PM",
        endTime: "03:00 PM",
        players: 2,
        subtotal: "INR 200",
      };
      const courtsData = ["Toronto", "Texas", "New York"];
      setBookingDetails(bookingDetailsData);
      setCourts(courtsData);
    };

    fetchData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/orderconfirmation");
  };

  return (
    <>
      <Header />
      <div>
        <div className="container">
          <div className="content">
            <section className="booking-steps py-30">
              <div className="container">
                <ul className="d-lg-flex justify-content-center align-items-center">
                  {bookingSteps.map((step, index) => (
                    <li key={index} className={index === 1 ? "active" : ""}>
                      <h5>
                        <a href="#">
                          <span>{step.step}</span>
                          {step.label}
                        </a>
                      </h5>
                    </li>
                  ))}
                </ul>
              </div>
            </section>

            <div className="content book-cage">
              <div className="container">
                <section className="card mb-40">
                  <div className="text-center mb-40">
                    <h3 className="mb-1">Book Turf</h3>
                    <p className="sub-title mb-0">
                      Hassle-free court bookings and state-of-the-art
                      facilities.
                    </p>
                  </div>
                  <div className="master-academy dull-whitesmoke-bg card">
                    <div className="row d-flex align-items-center justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <div className="d-sm-flex justify-content-start align-items-center">
                          <a href="#">
                            <img
                              className="corner-radius-10"
                              src="assets/img/master-academy.png"
                              alt="Venue"
                            />
                          </a>
                          <div className="info">
                            <div className="d-flex justify-content-start align-items-center mb-3">
                              <span className="text-white dark-yellow-bg color-white me-2 d-flex justify-content-center align-items-center">
                                4.5
                              </span>
                              <span>300 Reviews</span>
                            </div>
                            <h3 className="mb-2">Manchester Academy</h3>
                            <p>
                              Manchester Academy: Where dreams meet excellence
                              in sports education and training.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <ul className="d-sm-flex align-items-center justify-content-evenly">
                          <li>
                            <h3 className="d-inline-block">INR 150</h3>
                            <span>/hr</span>
                            <p>up to 1 Player</p>
                          </li>
                          <li>
                            <span>
                              <i className="feather-plus"></i>
                            </span>
                          </li>
                          <li>
                            <h3 className="d-inline-block">INR 500</h3>
                            <span>/hr</span>
                            <p>
                              each additional Players <br />
                              up to 6 Players max
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                    <section className="card booking-form">
                      <h3 className="border-bottom">Booking Form</h3>
                      <form onSubmit={handleSubmit}>
                        {/* <div className="mb-3">
                          <label htmlFor="date" className="form-label">
                            From
                          </label>
                          <div className="form-icon">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select Date"
                              id="date"
                            />
                            <span className="cus-icon">
                              <i className="feather-calendar icon-date"></i>
                            </span>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="start-time" className="form-label">
                            Start Time
                          </label>
                          <div className="form-icon">
                            <input
                              type="text"
                              className="form-control newtimepicker"
                              id="start-time"
                              placeholder="Select Start Time"
                            />
                            <span className="cus-icon">
                              <i className="feather-clock icon-time"></i>
                            </span>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="end-time" className="form-label">
                            End Time
                          </label>
                          <div className="form-icon">
                            <input
                              type="text"
                              className="form-control newtimepicker"
                              id="end-time"
                              placeholder="Select End Time"
                            />
                            <span className="cus-icon">
                              <i className="feather-clock icon-time"></i>
                            </span>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="court" className="form-label">
                            Court
                          </label>
                          <select className="select" id="court">
                            <option>Select City</option>
                            {courts.map((court, index) => (
                              <option key={index} value={court}>
                                {court}
                              </option>
                            ))}
                          </select>
                        </div>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button> */}
                        {/* <DayTimePicker timeSlotSizeMinutes={60} /> */}
                        <Dateandtimepicker></Dateandtimepicker>
                      </form>
                    </section>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                    {bookingDetails && (
                      <aside className="card booking-details">
                        <h3 className="border-bottom">Booking Details</h3>
                        <ul>
                          <li>
                            <i className="fa-regular fa-building me-2"></i>
                            {bookingDetails.court}
                            <span className="x-circle"></span>
                          </li>
                          <li>
                            <i className="feather-calendar me-2"></i>
                            {Dateandtimepicker.date}
                          </li>

                          <li className="d-flex align-items-center">
                            <i className="feather-users me-2"></i>
                            <div className="input-group">
                              <span className="input-group-text">
                                Total Players
                              </span>
                              <input
                                type="number"
                                className="form-control"
                                min="1"
                                value={bookingDetails.players}
                                onChange={(e) =>
                                  setBookingDetails({
                                    ...bookingDetails,
                                    players: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </li>
                        </ul>
                        <div className="d-grid btn-block">
                          <button type="button" className="btn btn-primary">
                            Subtotal: {bookingDetails.subtotal}
                          </button>
                        </div>
                      </aside>
                    )}
                  </div>
                </div>
                <div className="text-center btn-row">
                  <button
                    className="btn btn-secondary btn-icon"
                    onClick={handleSubmit}
                  >
                    Next <i className="feather-arrow-right-circle ms-1"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BookingPage;
