import Bookingsearchbar from "./Bookingsearchbar";
import Bookinglist from "./Bookinglist";
import Header from "../../Home/Header";
import Footer from "../../Footer/Footer";

const Mybookinghome = () => {
  return (
    <>
      <Header></Header>
      <Bookingsearchbar></Bookingsearchbar>
      <Bookinglist></Bookinglist>
      <Footer></Footer>
    </>
  );
};

export default Mybookinghome;
