import Bookingsearchbar from "./Bookingsearchbar";

const Cancelledbookings = () => {
  return (
    <>
      <Bookingsearchbar></Bookingsearchbar>

      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="court-tab-content">
                <div className="card card-tableset">
                  <div className="card-body">
                    <div className="coache-head-blk">
                      <div className="row align-items-center">
                        <div className="col-md-5">
                          <div className="court-table-head">
                            <h4>My Bookings</h4>
                            <p>
                              Track and manage your cancelled court bookings
                            </p>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="table-search-top">
                            <div id="tablefilter"></div>
                            <div className="request-coach-list">
                              <div className="card-header-btns">
                                <nav>
                                  <div className="nav nav-tabs" role="tablist">
                                    <button
                                      className="nav-link active"
                                      id="nav-Recent-tab"
                                      data-bs-toggle="tab"
                                      data-bs-target="#nav-Recent"
                                      type="button"
                                      role="tab"
                                      aria-controls="nav-Recent"
                                      aria-selected="true"
                                    >
                                      Courts
                                    </button>
                                    {/* <button
                                      className="nav-link"
                                      id="nav-RecentCoaching-tab"
                                      data-bs-toggle="tab"
                                      data-bs-target="#nav-RecentCoaching"
                                      type="button"
                                      role="tab"
                                      aria-controls="nav-RecentCoaching"
                                      aria-selected="false"
                                    >
                                      Coaches
                                    </button> */}
                                  </div>
                                </nav>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="nav-Recent"
                        role="tabpanel"
                        aria-labelledby="nav-Recent-tab"
                        tabIndex="0"
                      >
                        <div className="table-responsive table-datatble">
                          <table className="table table1">
                            <thead className="thead-light">
                              <tr>
                                {/* <th>Court Name</th> */}
                                <th>Date & Time </th>
                                <th>Payment</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {/* <td>
                                  <h2 className="table-avatar">
                                    <a
                                      href="#"
                                      className="avatar avatar-sm flex-shrink-0"
                                    >
                                      <img
                                        className="avatar-img"
                                        src="assets/img/booking/booking-01.jpg"
                                        alt="User"
                                      />
                                    </a>
                                    <span className="table-head-name flex-grow-1">
                                      <a
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#cancel-court"
                                      >
                                        Leap Sports Academy
                                      </a>
                                      <span>Court 1 </span>
                                    </span>
                                  </h2>
                                </td> */}
                                <td className="table-date-time">
                                  <h4>
                                    Mon, Jul 11<span>06:00 PM - 08:00 PM</span>
                                  </h4>
                                </td>
                                <td>
                                  <span className="pay-dark fs-16">
                                    INR 150
                                  </span>
                                </td>
                                <td>
                                  <span className="badge bg-danger">
                                    <img
                                      src="assets/img/icons/delete.svg"
                                      alt="Icon"
                                      className="me-1"
                                    />
                                    Cancelled
                                  </span>
                                </td>
                                <td className="text-end">
                                  <div className="dropdown dropdown-action table-drop-action">
                                    <a
                                      href="#"
                                      className="action-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-h"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      {/* <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#cancel-court-modal"
                                      >
                                        <i className="feather-briefcase"></i>
                                        Refund
                                      </a> */}
                                      <a className="dropdown-item" href="#">
                                        <i className="feather-trash"></i>Delete
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-RecentCoaching"
                        role="tabpanel"
                        aria-labelledby="nav-RecentCoaching-tab"
                        tabIndex="0"
                      >
                        <div className="table-responsive table-datatble">
                          <table className="table table1">
                            <thead className="thead-light">
                              <tr>
                                <th>Coach Name</th>
                                <th>Booking Type</th>
                                <th>Date & Time </th>
                                <th>Payment</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <h2 className="table-avatar">
                                    <a
                                      href="#"
                                      className="avatar avatar-sm flex-shrink-0"
                                    >
                                      <img
                                        className="avatar-img"
                                        src="assets/img/featured/featured-05.jpg"
                                        alt="User"
                                      />
                                    </a>
                                    <span className="table-head-name flex-grow-1">
                                      <a
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#cancel-coach"
                                      >
                                        Kevin Anderson
                                      </a>
                                      <span className="book-active">
                                        Booked on : 25 May 2023
                                      </span>
                                    </span>
                                  </h2>
                                </td>
                                <td>Onetime</td>
                                <td className="table-date-time">
                                  <h4>
                                    Mon, Jul 11<span>06:00 PM - 08:00 PM</span>
                                  </h4>
                                </td>
                                <td>
                                  <span className="pay-dark fs-16">
                                    INR 150
                                  </span>
                                </td>
                                <td>
                                  <span className="badge bg-danger">
                                    <img
                                      src="assets/img/icons/delete.svg"
                                      alt="Icon"
                                      className="me-1"
                                    />
                                    Cancelled
                                  </span>
                                </td>
                                <td className="text-end">
                                  <div className="dropdown dropdown-action table-drop-action">
                                    <a
                                      href="#"
                                      className="action-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-h"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#cancel-coach-modal"
                                      >
                                        <i className="feather-briefcase"></i>
                                        Refund
                                      </a>
                                      <a className="dropdown-item" href="#">
                                        <i className="feather-trash"></i>Delete
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-footer">
                  <div className="row">
                    <div className="col-md-6">
                      <div id="tablelength"></div>
                    </div>
                    <div className="col-md-6">
                      <div id="tablepage"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Cancelledbookings;
