import React from "react";
import { Heart, MapPin, Calendar } from "react-feather";
import "aos/dist/aos.css";

const venues = [
  {
    id: 1,
    imgSrc: "assets/img/venues/venues-01.jpg",
    tag: "Featured",
    price: "INR450/hr",
    rating: "4.2",
    reviews: "300 Reviews",
    title: "Sarah Sports Academy",
    description:
      "Elevate your athletic journey at Sarah Sports Academy, where excellence meets opportunity.",
    address: "Port Alsworth, AK",
    nextAvailability: "15 May 2023",
    ownerImg: "assets/img/profiles/avatar-01.jpg",
    ownerName: "Mart Sublin",
  },
  {
    id: 2,
    imgSrc: "assets/img/venues/venues-02.jpg",
    tag: "Top Rated",
    price: "INR200/hr",
    rating: "5.0",
    reviews: "150 Reviews",
    title: "Badminton Academy",
    description:
      "Unleash your badminton potential at our premier Badminton Academy, where champions are made.",
    address: "Sacramento, CA",
    nextAvailability: "15 May 2023",
    ownerImg: "assets/img/profiles/avatar-02.jpg",
    ownerName: "Rebecca",
  },
  {
    id: 3,
    imgSrc: "assets/img/venues/venues-03.jpg",
    price: "INR350/hr",
    rating: "4.7",
    reviews: "120 Reviews",
    title: "Manchester Academy",
    description:
      "Manchester Academy: Where dreams meet excellence in sports education and training.",
    address: "Guysville, OH",
    nextAvailability: "16 May 2023",
    ownerImg: "assets/img/profiles/avatar-03.jpg",
    ownerName: "Andrew",
  },
  {
    id: 4,
    imgSrc: "assets/img/venues/venues-04.jpg",
    tag: "Featured",
    price: "INR450/hr",
    rating: "4.5",
    reviews: "300 Reviews",
    title: "ABC Sports Academy",
    description:
      "Unleash your badminton potential at our premier ABC Sports Academy, where champions are made.",
    address: "Little Rock, AR",
    nextAvailability: "17 May 2023",
    ownerImg: "assets/img/profiles/avatar-04.jpg",
    ownerName: "Mart Sublin",
  },
];

const FeaturedVenue = () => {
  return (
    <section className="section featured-venues">
      <div className="container">
        <div className="section-heading aos" data-aos="fade-up">
          <h2>
            Featured <span>Venues</span>
          </h2>
          <p className="sub-title">
            Advanced sports venues offer the latest facilities, dynamic and
            unique environments for enhanced badminton performance.
          </p>
        </div>
        <div className="row">
          <div className="featured-slider-group">
            <div className="owl-carousel featured-venues-slider owl-theme">
              {venues.map((venue) => (
                <div
                  className="featured-venues-item aos"
                  data-aos="fade-up"
                  key={venue.id}
                >
                  <div className="listing-item mb-0">
                    <div className="listing-img">
                      <a href="/shopping">
                        <img src={venue.imgSrc} alt="Venue" />
                      </a>
                      <div className="fav-item-venues">
                        {venue.tag && (
                          <span className="tag tag-blue">{venue.tag}</span>
                        )}
                        <h5 className="tag tag-primary">{venue.price}</h5>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="list-reviews">
                        <div className="d-flex align-items-center">
                          <span className="rating-bg">{venue.rating}</span>
                          <span>{venue.reviews}</span>
                        </div>
                        {/* <a href="/shopping" className="fav-icon">
                          <Heart />
                        </a> */}
                      </div>
                      <h3 className="listing-title">
                        <p href="#">{venue.title}</p>
                      </h3>
                      <div className="listing-details-group">
                        <p>{venue.description}</p>
                        <ul>
                          <li>
                            <span>
                              <MapPin /> {venue.address}
                            </span>
                          </li>
                          <li>
                            <span>
                              <Calendar /> Next Availability :{" "}
                              <span className="primary-text">
                                {venue.nextAvailability}
                              </span>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-button">
                        <div className="listing-venue-owner">
                          <p className="navigation" href="#">
                            <img src={venue.ownerImg} alt="Venue Owner" />
                            {venue.ownerName}
                          </p>
                        </div>
                        <a href="/booknow" className="user-book-now">
                          <span>
                            <Calendar className="me-2" />
                          </span>
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="view-all text-center aos" data-aos="fade-up">
          <a
            href="/shopping"
            className="btn btn-secondary d-inline-flex align-items-center"
          >
            View All Featured
            <span className="lh-1">
              <i className="feather-arrow-right-circle ms-2"></i>
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default FeaturedVenue;
