// import Groupcoaching from "../../Home/Group-coaching";
// import Latestnews from "../../Home/Latest-news";
// import Newslettersport from "../../Home/Newsletter-sport";
// import Ourtestimonial from "../../Home/Our-testimonials";
import Aboutusheader from "./Aboutusheader";
import Ourteam from "./Ourteam";
import Vision from "./Vision";
import Footer from "../../Footer/Footer";
import Header from "../../Home/Header";
import "./Aboutushome.css";

const Aboutushome = () => {
  return (
    <>
      <Header></Header>
      <div className="aboutusheader">
        <Ourteam></Ourteam>
      </div>
      <Vision></Vision>
      <Aboutusheader></Aboutusheader>

      {/* <Groupcoaching></Groupcoaching> */}
      {/* <Ourtestimonial></Ourtestimonial> */}
      {/* <Latestnews></Latestnews> */}
      {/* <Newslettersport></Newslettersport> */}
      <Footer></Footer>
    </>
  );
};
export default Aboutushome;
