import React, { useState } from "react";
import {
  Card,
  Button,
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { Navigate } from "react-router-dom";

const generateHours = () => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    const hour = i % 12 === 0 ? 12 : i % 12;
    const amPm = i < 12 ? "AM" : "PM";
    const formattedHour = `${hour}:00 ${amPm}`;
    hours.push(formattedHour);
  }
  return hours;
};

const hourlypacks = [
  {
    image: "Listimg/list01.avif",
    availability: "Available",
    price: "INR 200",
    title: "TurfMasters",
  },
  {
    image: "Listimg/list02.avif",
    availability: "Booked",
    price: "INR 300",
    title: "GreenField Solutions",
  },
  {
    image: "Listimg/list03.avif",
    availability: "Booked",
    price: "INR 300",
    title: "PrimeTurf Enterprises",
  },
  {
    image: "Listimg/list04.avif",
    availability: "Booked",
    price: "INR 300",
    title: "EverGreen Turf Co",
  },
  {
    image: "Listimg/list05.avif",
    availability: "Booked",
    price: "INR 300",
    title: "ProTurf Innovations",
  },
  {
    image: "Listimg/list06.avif",
    availability: "Booked",
    price: "INR 300",
    title: " Elite Turf Services",
  },
  {
    image: "Listimg/list07.avif",
    availability: "Booked",
    price: "INR 300",
    title: " GreenScape Turfing",
  },
  {
    image: "Listimg/list08.avif",
    availability: "Booked",
    price: "INR 300",
    title: " TurfGenix",
  },
  {
    image: "Listimg/list09.avif",
    availability: "Booked",
    price: "INR 300",
    title: "LushLand Turfworks",
  },
];

const Hourlypacks = () => {
  const [selectedHour, setSelectedHour] = useState(hourlypacks.map(() => ""));

  const handleSelect = (selectedKey, packIndex) => {
    const newSelectedHour = [...selectedHour];
    newSelectedHour[packIndex] = selectedKey;
    setSelectedHour(newSelectedHour);
  };

  const hours = generateHours();

  return (
    <Container>
      <div className="section-heading aos" data-aos="fade-up">
        <h2>
          Explore <span>Convenient Hours</span>
        </h2>
      </div>
      <Row>
        {hourlypacks.map((pack, index) => (
          <Col key={index} md={4} className="mb-4">
            <Card>
              <Card.Img variant="top" src={pack.image} alt="image" />
              <Card.Body>
                <Card.Title>{pack.title}</Card.Title>
                {/* <DropdownButton
                  id={`dropdown-basic-button-${index}`}
                  title={selectedHour[index] || "Select Hour"}
                  onSelect={(eventKey) => handleSelect(eventKey, index)}
                >
                  {hours.map((hour, hourIndex) => (
                    <Dropdown.Item key={hourIndex} eventKey={hour}>
                      {hour}
                    </Dropdown.Item>
                  ))}
                </DropdownButton> */}
                <Card.Text>
                  Availability: {pack.availability}
                  <br />
                  PerHour: {pack.price}
                </Card.Text>
                <Button as="a" href="/booknow" variant="primary">
                  Book Now
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Hourlypacks;
