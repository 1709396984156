import React, { useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";
import "./Contactus.css";
import axios from "axios";

const Contactus = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    subject: "",
    comments: "",
  });

  const handleonchange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handlesubmit = async () => {
    try {
      await axios.post(
        "https://lawnlinkapi.dremerz.net/sendinquiryemail",
        formData
      );
      alert("enquiry email send successfully");
    } catch (error) {
      alert("email send failed try again");
    }
  };

  return (
    <>
      <Header></Header>
      <div className="content blog-details contact-group contact-us-page new-contactus">
        <div className="container">
          <h2 className="text-center mb-40">Contact Information</h2>
          <div className="row mb-40">
            <div className="col-12 col-sm-12 col-md-6 col-lg-4">
              <div className="d-flex justify-content-start align-items-center details">
                <i className="feather-mail d-flex justify-content-center align-items-center"></i>
                <div className="info">
                  <h4>Email Address</h4>
                  <p>
                    <a href="mailto:protected@example.com">
                      lawnlink2024@gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4">
              <div className="d-flex justify-content-start align-items-center details">
                <i className="feather-phone-call d-flex justify-content-center align-items-center"></i>
                <div className="info">
                  <h4>Phone Number</h4>
                  <p>9488739021</p>
                  <p>7904922816</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4">
              <div className="d-flex justify-content-start align-items-center details">
                <i className="feather-map-pin d-flex justify-content-center align-items-center"></i>
                <div className="info">
                  <h4>Location</h4>
                  <p>Chengalpattu, Tamil Nadu 603308</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="google-maps">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.1490225360053!2d80.2707!3d13.0827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1sHWR7+7FF!2sExson%20Academy%2C%20Chennai%2C%20Tamil%20Nadu%2C%20India!5e0!3m2!1sen!2sin!4v1670922579281!5m2!1sen!2sin"
                  height="445"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Google Map"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <section className="section dull-bg">
          <div className="container">
            <h2 className="text-center mb-40">
              Reach out to us and let's smash your inquiries
            </h2>
            <form className="contact-us" onSubmit={handlesubmit}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 mb-3">
                  <label htmlFor="first-name" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="first-name"
                    placeholder="Enter First Name"
                    value={formData.firstname}
                    onChange={handleonchange}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 mb-3">
                  <label htmlFor="last-name" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="last-name"
                    placeholder="Enter Last Name"
                    value={formData.lastname}
                    onChange={handleonchange}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 mb-3">
                  <label htmlFor="e-mail" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="e-mail"
                    placeholder="Enter Email Address"
                    value={formData.email}
                    onChange={handleonchange}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    placeholder="Enter Phone Number"
                    value={formData.phone}
                    onChange={handleonchange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-3">
                  <label htmlFor="subject" className="form-label">
                    Subject
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="subject"
                    placeholder="Enter Subject"
                    value={formData.subject}
                    onChange={handleonchange}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="comments" className="form-label">
                  Comments
                </label>
                <textarea
                  className="form-control"
                  id="comments"
                  rows="3"
                  placeholder="Enter Comments"
                  value={formData.comments}
                  onChange={handleonchange}
                ></textarea>
              </div>
              <button
                type="submit"
                className="btn btn-secondary d-flex align-items-center"
              >
                Submit<i className="feather-arrow-right-circle ms-2"></i>
              </button>
            </form>
          </div>
        </section>
        <Footer></Footer>
      </div>
    </>
  );
};

export default Contactus;
