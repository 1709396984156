import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import CreateHostMatch from "./Createhostmatch";

const Groundform = () => {
  const [formData, setFormData] = useState({
    tournamentPoster: null,
    matchType: "",
    entryFee: "",
    dateTime: "",
    rules: "",
    address: "",
    contactNo: "",
    groupLink: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleFileChange = (event) => {
    setFormData({ ...formData, tournamentPoster: event.target.files[0] });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.matchType) newErrors.matchType = "Match Type is required";
    if (!formData.entryFee) newErrors.entryFee = "Entry Fee is required";
    if (!formData.dateTime) newErrors.dateTime = "Date and Time are required";
    if (!formData.address) newErrors.address = "Address is required";
    if (!formData.contactNo) newErrors.contactNo = "Contact Number is required";

    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formDataToSubmit = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSubmit.append(key, formData[key]);
    });

    fetch("/api/ground-post", {
      method: "POST",
      body: formDataToSubmit,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccessMessage("Post successful!");
          setFormData({
            tournamentPoster: null,
            matchType: "",
            entryFee: "",
            dateTime: "",
            rules: "",
            address: "",
            contactNo: "",
            groupLink: "",
          });
        } else {
          setErrorMessage("Post failed. Please try again.");
        }
      })
      .catch((error) => {
        setErrorMessage("An error occurred. Please try again.");
      });
  };

  return (
    <>
      <CreateHostMatch />
      <div className="content">
        <div className="container">
          <div className="section-heading aos">
            <h2>
              Ground <span>Booking Form</span>
            </h2>
          </div>
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="tournamentPoster">
              <Form.Label>Ground: Post Your Tournament Poster</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
            <Form.Group controlId="matchType">
              <Form.Label>Match Type</Form.Label>
              <Form.Control
                type="text"
                value={formData.matchType}
                onChange={handleChange}
                isInvalid={!!errors.matchType}
              />
              <Form.Control.Feedback type="invalid">
                {errors.matchType}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="entryFee">
              <Form.Label>Entry Fee</Form.Label>
              <Form.Control
                type="number"
                value={formData.entryFee}
                onChange={handleChange}
                isInvalid={!!errors.entryFee}
              />
              <Form.Control.Feedback type="invalid">
                {errors.entryFee}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="dateTime">
              <Form.Label>Date and Time</Form.Label>
              <Form.Control
                type="datetime-local"
                value={formData.dateTime}
                onChange={handleChange}
                isInvalid={!!errors.dateTime}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dateTime}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="rules">
              <Form.Label>Rules</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={formData.rules}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                value={formData.address}
                onChange={handleChange}
                isInvalid={!!errors.address}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="contactNo">
              <Form.Label>Contact No</Form.Label>
              <Form.Control
                type="text"
                value={formData.contactNo}
                onChange={handleChange}
                isInvalid={!!errors.contactNo}
              />
              <Form.Control.Feedback type="invalid">
                {errors.contactNo}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="groupLink">
              <Form.Label>Group Link</Form.Label>
              <Form.Control
                type="text"
                value={formData.groupLink}
                onChange={handleChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Post
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Groundform;
