import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

const Changepassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [customUserId, setcustomUserId] = useState(null);

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMsg("New password and confirm password do not match.");
      setError(true);
      return;
    }

    try {
      const response = await axios.post(
        "https://lawnlinkapi.dremerz.net/changeuserpassword",
        {
          oldPassword,
          newPassword,
          customUserId,
        }
      );
      if (response.status === 200) {
        setSuccess(true);
        setErrorMsg(response.data.message);
      } else {
        setErrorMsg(response.data.message);
        setError(true);
      }
    } catch (err) {
      setErrorMsg("Failed to change password.");
      setError(true);
    }
  };

  const handleReset = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#changepassword") {
      var element = document.getElementById("changepassword");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      setcustomUserId(user.custom_user_id);
    }
  }, []);

  return (
    <div className="content court-bg" id="changepassword">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="profile-detail-group">
              <div className="section-heading aos" data-aos="fade-up">
                <h2>
                  <span>Change Password</span>
                </h2>
              </div>
              <div className="card">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-7 col-md-7">
                      <div className="input-space">
                        <label className="form-label">Old Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          placeholder="Enter Old Password"
                          value={oldPassword}
                          onChange={handleOldPasswordChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-7">
                      <div className="input-space">
                        <label className="form-label">New Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="new-password"
                          placeholder="Enter New Password"
                          value={newPassword}
                          onChange={handleNewPasswordChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-7">
                      <div className="input-space mb-0">
                        <label className="form-label">Confirm Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="confirm-password"
                          placeholder="Enter Confirm Password"
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="save-changes text-end">
                    <button
                      type="button"
                      className="btn btn-primary reset-profile"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="btn btn-secondary save-profile"
                    >
                      Save Change
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {success && (
        <div
          className="modal fade show"
          tabIndex="-1"
          aria-labelledby="successModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="successModalLabel">
                  Success
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setSuccess(false)}
                ></button>
              </div>
              <div className="modal-body">
                Your password has been changed successfully.
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => setSuccess(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {error && (
        <div
          className="modal fade show"
          tabIndex="-1"
          aria-labelledby="errorModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="errorModalLabel">
                  Error
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setError(false)}
                ></button>
              </div>
              <div className="modal-body">{errorMsg}</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => setError(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Changepassword;
