import React from "react";

const plans = [
  {
    id: 1,
    type: "Professional",
    imgSrc: "assets/img/icons/price-01.svg",
    price: "INR 300",
    month: "3 Months",
    features: [
      "Quality Checked By LawnLink",
      "Future Updates",
      "Technical Support",
      "Add Listing",
      "Approval of Listing",
    ],
    active: false,
    details:
      "Use, by you or one client, in a single end product which end users. charged for. The total price includes the item price and a buyer fee.",
  },
  {
    id: 2,
    type: "Expert",
    imgSrc: "assets/img/icons/price-02.svg",
    price: "INR 600",
    month: "6 Months",

    features: [
      "Quality Checked By LawnLink",
      "Future Updates",
      "6 Months Technical Support",
      "Add Listing",
      "Approval of Listing",
    ],
    active: true,
    details:
      "Use, by you or one client, in a single end product which end users. charged for. The total price includes the item price and a buyer fee.",
  },
  {
    id: 3,
    type: "Enterprise",
    imgSrc: "assets/img/icons/price-03.svg",
    price: "INR 1000",
    month: "12 Months",

    features: [
      "Quality Checked By LawnLink",
      "Future Updates",
      "Technical Support",
      "Add Listing",
      "Approval of Listing",
    ],
    active: false,
    details:
      "Use, by you or one client, in a single end product which end users. charged for. The total price includes the item price and a buyer fee.",
  },
];

const Featuredplan = () => {
  return (
    <section className="section featured-plan">
      <div className="work-img">
        <div className="work-img-right">
          <img src="assets/img/bg/work-bg.png" alt="Icon" />
        </div>
      </div>
      <div className="container">
        <div className="section-heading aos" data-aos="fade-up">
          <h2>
            We Have Excellent <span>Plans For You</span>
          </h2>
          <p className="sub-title">
            Choose from monthly plans for uninterrupted access to our premium
            turf facilities. Join us and enjoy seamless and exceptional
            experiences.
          </p>
        </div>
        {/* <div className="interset-btn aos" data-aos="fade-up">
          <div className="status-toggle d-inline-flex align-items-center">
            Monthly
            <input type="checkbox" id="status_1" className="check" />
            <label htmlFor="status_1" className="checktoggle">
              checkbox
            </label>
            Yearly
          </div>
        </div> */}
        <div className="price-wrap aos" data-aos="fade-up">
          <div className="row justify-content-center">
            {plans.map((plan) => (
              <div key={plan.id} className="col-lg-4 d-flex col-md-6">
                <div
                  className={`price-card flex-fill ${
                    plan.active ? "active-price" : ""
                  }`}
                >
                  <div
                    className={`price-head ${
                      plan.active ? "expert-price" : ""
                    }`}
                  >
                    <img src={plan.imgSrc} alt="Price" />
                    <h3>{plan.type}</h3>
                    {plan.active && <span>Recommended</span>}
                  </div>
                  <div className="price-body">
                    <div className="per-month">
                      <h2>
                        <sup></sup>
                        <span>{plan.price}</span>
                      </h2>
                      <span>{plan.month}</span>
                    </div>
                    <div className="features-price-list">
                      <h5>Features</h5>
                      <p>Everything in our free Upto 10 users.</p>
                      <ul>
                        {plan.features.map((feature, index) => (
                          <li
                            key={index}
                            className={
                              feature.includes("Add Listing") ||
                              feature.includes("Approval of Listing")
                                ? "inactive"
                                : "active"
                            }
                          >
                            <i
                              className={`feather-${
                                feature.includes("Add Listing") ||
                                feature.includes("Approval of Listing")
                                  ? "x-circle"
                                  : "check-circle"
                              }`}
                            ></i>
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="price-choose">
                      <a href="#" className="btn viewdetails-btn">
                        Choose Plan
                      </a>
                    </div>
                    <div className="price-footer">
                      <p>{plan.details}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Featuredplan;
