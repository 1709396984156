import React from "react";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";
import "./Blogdetails.css";

const Blogdetails = () => {
  const blogPost = {
    imgSrc: "assets/img/blog/blog-04.jpg",
    imgAlt: "Venue",
    tag: "Badminton",
    authorImg: "assets/img/profiles/avatar-01.jpg",
    authorName: "Orlando Waters",
    date: "15 May 2023",
    comments: 30,
    title: "Mastering the Badminton Basics: A Guide for Beginners",
    content: [
      "There are many variations of passages of at Lorem Ipsum available but the majority suffered that dummy is alteration. There are many variations of passages of Lorem Ipsum available but the or majority have that suffered alteration words which don't look even slightly believable. There are many available but the majority the have suffered alteration. There are many variations of dummy passages Lorem majority the have the suffered alteration.",
      "There are many variations of passages of at Lorem Ipsum available but the majority the have too suffered alteration. There are many variations of passages of Lorem Ipsum available majority have that suffered alteration words which don't look even slightly believable. There are available but the majority the have suffered alteration.",
    ],
    quote:
      "There are many variations of passages of at Lorem Ipsum available but the majority the have too suffered alteration. There are many variations of passages of Lorem Ipsum vailable majority have that suffered alteration words which don't look even slightly believable.",
    images: [
      "assets/img/blog/blog-05.jpg",
      "assets/img/blog/blog-06.jpg",
      "assets/img/blog/blog-07.jpg",
    ],
    listItems: [
      "dummy passages Lorem majority",
      "Lorem Ipsum is not simply random text",
      "It has roots in a piece of classical Latin literature",
      "There are many variations of passages of Lorem Ipsum",
      "Various versions have evolved over the years",
    ],
    tags: ["Rackets", "New Game", "Dresses"],
    socialLinks: {
      facebook: "#;",
      linkedin: "#;",
      instagram: "#;",
      twitter: "#;",
      pinterest: "#;",
    },
  };

  return (
    <>
      <Header></Header>

      <div className="section-heading aos new-title" data-aos="fade-up">
        <h2>
          Blog <span>Details</span>
        </h2>
      </div>
      <div className="content blog-details blog news-content">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-10 col-lg-8 mx-auto">
              <div className="featured-venues-item">
                <div className="listing-item blog-info">
                  <div className="listing-img news-content">
                    <a href="">
                      <img
                        src={blogPost.imgSrc}
                        className="img-fluid"
                        alt={blogPost.imgAlt}
                      />
                    </a>
                    <div className="fav-item-venues news-sports">
                      <span className="tag tag-blue">{blogPost.tag}</span>
                    </div>
                  </div>
                  <div className="listing-content news-content">
                    <div className="listing-venue-owner blog-detail-owner d-lg-flex justify-content-between align-items-center">
                      <div className="navigation">
                        <a href="#">
                          <img src={blogPost.authorImg} alt="User" />
                          {blogPost.authorName}
                        </a>
                        <span>
                          <i className="feather-calendar"></i>
                          {blogPost.date}
                        </span>
                        <span>
                          <i className="far fa-comment-alt"></i>
                          {blogPost.comments} Comments
                        </span>
                      </div>
                      <a className="btn btn-primary">
                        <img
                          src="assets/img/icons/grid.svg"
                          className="img-fluid"
                          alt="Icon"
                        />
                        Rules of Game
                      </a>
                    </div>

                    <h2 className="listing-title">
                      <a href="#">{blogPost.title}</a>
                    </h2>
                    {blogPost.content.map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))}
                    <div className="gradient-bg d-flex justify-content-between align-items-center">
                      <img
                        src="assets/img/icons/comment-quotes.svg"
                        className="img-fluid"
                        alt="Icon"
                      />
                      <p className="mb-0">{blogPost.quote}</p>
                    </div>
                    {blogPost.images.map((image, index) => (
                      <a href="#" key={index}>
                        <img src={image} className="img-fluid" alt="Venue" />
                      </a>
                    ))}
                    <p>
                      Lorem Ipsum available but the or majority have that
                      suffered alteration words which don't look even slightly
                      believable. There are many available but the majority the
                      have suffered alteration. There are many variations of
                      dummy passages Lorem majority the have the suffered
                      alteration. There are many variations of passages of at
                      Lorem Ipsum available but the majority suffered that dummy
                      is alteration. There are many variations of passages of
                      Lorem Ipsum available but the or majority have that
                      suffered alteration words which don't look even slightly
                      believable.
                    </p>
                    <ul className="text-list">
                      {blogPost.listItems.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                    <p>
                      Lorem Ipsum available but the or majority have that
                      suffered alteration words which don't look even slightly
                      believable. There are many available but the majority the
                      have suffered alteration. There are many variations of
                      dummy passages Lorem majority the have the suffered
                      alteration.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex align-items-center tags-wrapper">
                    <h6>Tags:</h6>
                    <ul className="tags">
                      {blogPost.tags.map((tag, index) => (
                        <li key={index}>
                          <a href="#" className="tag">
                            {tag}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-lg-end align-items-center social-medias-wrapper">
                    <h6>Share on :</h6>
                    <ul className="social-medias d-flex">
                      <li className="facebook">
                        <a href={blogPost.socialLinks.facebook}>
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="linkedin">
                        <a href={blogPost.socialLinks.linkedin}>
                          <i className="fa-brands fa-linkedin"></i>
                        </a>
                      </li>
                      <li className="instagram">
                        <a href={blogPost.socialLinks.instagram}>
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li className="twitter">
                        <a href={blogPost.socialLinks.twitter}>
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </li>
                      <li className="pinterest">
                        <a href={blogPost.socialLinks.pinterest}>
                          <i className="fa-brands fa-pinterest"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Blogdetails;
