import React from "react";

const Earnmoney = () => {
  const tabs = [
    {
      id: "nav-Recent",
      title: "Earn Money Renting Out Your Private Turf On Lawnlink",
      description:
        "Join our network of private facility owners, offering rentals to local players, coaches, and teams.",
      features: [
        "insurance",
        "Build of Trust",
        "Protected Environment for Your Activities",
      ],
    },
    {
      id: "nav-RecentCoaching",
      title: "Earn Money Renting Out Your Private Coaches On LawnLink",
      description:
        "Join our network of private facility owners, offering rentals to local players, coaches, and teams.",
      features: [
        "INR 1,000,000 liability insurance",
        "Build of Trust",
        "Protected Environment for Your Activities",
      ],
    },
  ];

  return (
    <section className="section earn-money">
      <div className="cock-img cock-position">
        <div className="cock-img-one">
          <img src="assets/img/icons/cock-01.svg" alt="Icon" />
        </div>
        <div className="cock-img-two">
          <img src="assets/img/icons/cock-02.svg" alt="Icon" />
        </div>
        <div className="cock-circle">
          <img src="assets/img/bg/cock-shape.png" alt="Icon" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="private-venue aos" data-aos="fade-up">
              <div className="convenient-btns become-owner" role="tablist">
                {tabs.map((tab, index) => (
                  <a
                    key={tab.id}
                    href="#"
                    className={`btn ${
                      index === 0
                        ? "btn-secondary become-venue"
                        : "btn-primary become-coche"
                    } d-inline-flex align-items-center nav-link ${
                      index === 0 ? "active" : ""
                    }`}
                    id={`nav-${tab.id}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#nav-${tab.id}`}
                    role="tab"
                    aria-controls={`nav-${tab.id}`}
                    aria-selected={index === 0}
                  >
                    {index === 0 ? "Become A Venue Member" : "Become A Member"}
                  </a>
                ))}
              </div>
              {tabs.map((tab) => (
                <div key={tab.id} className="tab-content">
                  <div
                    className={`tab-pane fade ${
                      tab.id === "nav-Recent" ? "show active" : ""
                    }`}
                    id={tab.id}
                    role="tabpanel"
                    aria-labelledby={`${tab.id}-tab`}
                    tabIndex="0"
                  >
                    <h2>{tab.title}</h2>
                    <p>{tab.description}</p>
                    <div className="earn-list">
                      <ul>
                        {tab.features.map((feature, idx) => (
                          <li key={idx}>
                            <i className="fa-solid fa-circle-check"></i>{" "}
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="convenient-btns">
                      <a
                        href="/signup"
                        className="btn btn-secondary d-inline-flex align-items-center"
                      >
                        <span className="lh-1">
                          <i className="feather-user-plus me-2"></i>
                        </span>
                        Join With Us
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Earnmoney;
