import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Bookingsearchbar.css";
import Header from "../../Home/Header";

const BookingSort = () => {
  const [weekSelection, setWeekSelection] = useState("This Week");
  const [sortSelection, setSortSelection] = useState("Relevance");

  const handleWeekChange = (e) => {
    setWeekSelection(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortSelection(e.target.value);
  };

  return (
    <>
      <Header></Header>
      <div className="content new-mybookingsearchbar">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sortby-section court-sortby-section">
                <div className="sorting-info">
                  <div className="row d-flex align-items-center">
                    <div className="col-xl-7 col-lg-7 col-sm-12 col-12">
                      <div className="coach-court-list">
                        <ul className="nav">
                          <li>
                            <NavLink
                              to="/mybookings"
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              Upcoming
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/completedbookings"
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              Completed
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/ongoingbookings"
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              On Going
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/cancelledbookings"
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              Cancelled
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-sm-12 col-12">
                      <div className="sortby-filter-group court-sortby">
                        {/* <div className="sortbyset week-bg">
                        <div className="sorting-select">
                          <select
                            className="form-control select"
                            value={weekSelection}
                            onChange={handleWeekChange}
                          >
                            <option>This Week</option>
                            <option> Day</option>
                          </select>
                        </div>
                      </div> */}
                        {/* <div className="sortbyset">
                        <span className="sortbytitle">Sort By</span>
                        <div className="sorting-select">
                          <select
                            className="form-control select"
                            value={sortSelection}
                            onChange={handleSortChange}
                          >
                            <option>Relevance</option>
                            <option>Price</option>
                          </select>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingSort;
