import React from "react";

const Servicesection = () => {
  const services = [
    {
      id: 1,
      imgSrc: "assets/img/services/service-01.jpg",
      title: "Lawn Rent",
      link: "/shopping",
    },
    {
      id: 2,
      imgSrc: "assets/img/services/service-02.jpg",
      title: "Group Training",
      link: "contactus",
    },
    {
      id: 3,
      imgSrc: "assets/img/services/service-03.jpg",
      title: "Training Program",
      link: "#",
    },
    {
      id: 4,
      imgSrc: "assets/img/services/service-04.jpg",
      title: "Private Lessons",
      link: "#",
    },
  ];

  return (
    <section className="section service-section">
      <div className="work-cock-img">
        <img src="assets/img/icons/work-cock.svg" alt="Service" />
      </div>
      <div className="container">
        <div className="section-heading aos" data-aos="fade-up">
          <h2>
            Explore Our <span>Services</span>
          </h2>
          <p className="sub-title">
            Fostering excellence and empowering sports growth through tailored
            services for athletes, coaches, and enthusiasts.
          </p>
        </div>
        <div className="row">
          {services.map(({ id, imgSrc, title, link }) => (
            <div key={id} className="col-lg-3 col-md-6 d-flex">
              <div className="service-grid w-100 aos" data-aos="fade-up">
                <div className="service-img">
                  <p href={link}>
                    <img src={imgSrc} className="img-fluid" alt={title} />
                  </p>
                </div>
                <div className="service-content">
                  <h4>
                    <p href={link}>{title}</p>
                  </h4>
                  {/* <p href={link}>Reach out us on</p> */}
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="view-all text-center aos" data-aos="fade-up">
          <p
            href="#"
            className="btn btn-secondary d-inline-flex align-items-center"
          >
            View All Services{" "}
            <span className="lh-1">
              <i className="feather-arrow-right-circle ms-2"></i>
            </span>
          </p>
        </div> */}
      </div>
    </section>
  );
};

export default Servicesection;
