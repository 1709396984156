import React, { useState } from "react";

const Payment = () => {
  const [paymentMethod, setPaymentMethod] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const bookingSteps = [
    { step: 1, label: "Book a Court" },
    { step: 2, label: "Order Confirmation" },
    { step: 3, label: "Personal Information" },
    { step: 4, label: "Payment" },
  ];

  const paymentMethods = [
    { id: "inlineRadio1", value: "Credit Card", label: "Credit Card" },
    { id: "inlineRadio2", value: "Paypal", label: "Paypal" },
    { id: "inlineRadio3", value: "upi", label: "Upi" },
  ];

  const orderSummaryItems = [
    { icon: "fa-regular fa-building", text: "Standard Synthetic Court 1" },
    { icon: "feather-calendar", text: "27, April 2023" },
    { icon: "feather-clock", text: "01:00 PM to 03:00 PM" },
    { icon: "feather-users", text: "6 Players" },
  ];

  const orderTotals = [
    { label: "Sub total", amount: "INR 250" },
    { label: "Additional Guest", amount: "INR 25" },
    { label: "Service charge", amount: "INR 70" },
    { label: "Order Total", amount: "INR 450" },
  ];

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  return (
    <div className="container">
      <div className="content">
        <div className="content">
          <div className="container">
            <section>
              <div className="text-center mb-40">
                <h3 className="mb-1">Payment</h3>
                <p className="sub-title mb-0">
                  Secure your booking, complete payment, and enjoy our
                  sophisticated facilities
                </p>
              </div>
              <div className="master-academy dull-whitesmoke-bg card mb-40">
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="d-sm-flex justify-content-start align-items-center">
                    <a href="#">
                      <img
                        className="corner-radius-10"
                        src="assets/img/master-academy.png"
                        alt="Venue"
                      />
                    </a>
                    <div className="info">
                      <h3 className="mb-2">Manchester Academy</h3>
                      <p>
                        Manchester Academy: Where dreams meet excellence in
                        sports education and training.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row checkout">
                <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                  <div className="card booking-details">
                    <h3 className="border-bottom">Order Summary</h3>
                    <ul className="list-unstyled">
                      {orderSummaryItems.map((item, index) => (
                        <li key={index}>
                          <i className={`${item.icon} me-2`}></i>
                          {item.text}
                          {index === 0 && <span className="x-circle"></span>}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                  <aside className="card payment-modes">
                    <h3 className="border-bottom">Checkout</h3>
                    <h6 className="mb-3">Select Payment Gateway</h6>
                    <div className="radio">
                      {paymentMethods.map((method) => (
                        <div
                          className="form-check form-check-inline mb-3"
                          key={method.id}
                        >
                          <input
                            className="form-check-input default-check me-2"
                            type="radio"
                            name="inlineRadioOptions"
                            id={method.id}
                            value={method.value}
                            checked={paymentMethod === method.value}
                            onChange={handlePaymentMethodChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={method.id}
                          >
                            {method.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    <hr />
                    <ul className="order-sub-total">
                      {orderTotals.map((total, index) => (
                        <li key={index}>
                          <p>{total.label}</p>
                          <h6>{total.amount}</h6>
                        </li>
                      ))}
                    </ul>
                    <div className="order-total d-flex justify-content-between align-items-center">
                      <h5>Order Total</h5>
                      <h5>INR 450</h5>
                    </div>
                    <div className="form-check d-flex justify-content-start align-items-center policy">
                      <div className="d-inline-block">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="policy"
                          checked={termsAccepted}
                          onChange={handleTermsChange}
                        />
                      </div>
                      <label className="form-check-label" htmlFor="policy">
                        By clicking 'Proceed', I agree to Lawnlink{" "}
                        <a href="privacy-policy#">Privacy Policy</a> and{" "}
                        <a href="terms-condition#">Terms of Use</a>
                      </label>
                    </div>
                    <div className="d-grid btn-block">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#bookingconfirmModal"
                        disabled={!termsAccepted}
                      >
                        Proceed INR 200
                      </button>
                    </div>
                  </aside>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
