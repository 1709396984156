import React from "react";
import { Heart, ArrowRight, Calendar } from "react-feather";
import "aos/dist/aos.css";

const coaches = [
  {
    id: 1,
    imgSrc: "assets/myoffer/offer1.avif",
    level: "Rookie",
    price: "INR250/hr",
    Upto: " Upto 40%",
    name: "TurfMasters",
  },
  {
    id: 2,
    imgSrc: "assets/myoffer/offer2.avif",
    level: "Intermediate",
    price: "INR150/hr",
    Upto: "Upto 30%",
    name: " CourtElite",
  },
  {
    id: 3,
    imgSrc: "assets/myoffer/offer3.avif",
    level: "Professional",
    price: "INR350/hr",
    Upto: "Upto 20%",
    name: " GameOn Turf",
  },
  {
    id: 4,
    imgSrc: "assets/myoffer/offer4.avif",
    level: "Rookie",
    price: "INR250/hr",
    Upto: "Upto 10%",
    name: " FieldFrenzy",
  },
  {
    id: 5,
    imgSrc: "assets/myoffer/offer05.avif",
    level: "Rookie",
    price: "INR250/hr",
    Upto: "Upto 20%",
    name: " SportSphere",
  },
];

const Featuredsection = () => {
  return (
    <section className="section featured-section">
      <div className="container">
        <div className="section-heading aos" data-aos="fade-up">
          <h2>
            Premium <span>Offers</span>
          </h2>
          <p className="sub-title">
            Uplift your Turf game with our featured coaches, personalized
            instruction, and expertise to achieve your goals.
          </p>
        </div>
        <div className="row">
          <div className="featured-slider-group aos" data-aos="fade-up">
            <div className="owl-carousel featured-coache-slider owl-theme">
              {coaches.map((coach) => (
                <div className="featured-venues-item" key={coach.id}>
                  <div className="listing-item mb-0">
                    <div className="listing-img">
                      <a href="/availableoffers">
                        <img src={coach.imgSrc} alt="User" />
                      </a>
                      <div className="fav-item-venues">
                        <span className="tag tag-blue">{coach.level}</span>
                        <div className="list-reviews coche-star"></div>
                      </div>
                      <div className="hour-list">
                        <h5 className="tag tag-primary">{coach.price}</h5>
                      </div>
                    </div>
                    <div className="listing-content list-coche-content">
                      <span>{coach.Upto}</span>
                      <h3>
                        <a href="/availableoffers">{coach.name}</a>
                      </h3>
                      <a href="/availableoffers">
                        <ArrowRight />
                      </a>
                      <a href="/availableoffers" className="icon-hover">
                        <Calendar />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="view-all text-center aos" data-aos="fade-up">
          <a
            href="#"
            className="btn btn-secondary d-inline-flex align-items-center"
          >
            View All Coaches{" "}
            <span className="lh-1">
              <i className="feather-arrow-right-circle ms-2"></i>
            </span>
          </a>
        </div> */}
      </div>
    </section>
  );
};

export default Featuredsection;
