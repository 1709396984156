import React from "react";

const BookingConfirmation = () => {
  const bookingSteps = [
    { step: 1, label: "Book a Court" },
    { step: 2, label: "Order Confirmation" },
    { step: 3, label: "Payment" },
  ];

  const bookingDetails = [
    { title: "Court Name", value: "Standard Synthetic Court 1" },
    { title: "Appointment Date", value: "Mon, Jul 11" },
    { title: "Appointment Start time", value: "05:25 AM" },
    { title: "Appointment End time", value: "06:25 AM" },
    { title: "Additional Players", value: "4" },
  ];

  const contactInformation = [
    { title: "Name", value: "Rodick Tramliar" },
    { title: "Contact Email Address", value: "[email protected]" },
    { title: "Phone Number", value: "+1 56565 556558" },
  ];

  const paymentInformation = [
    { title: "Cage Total", value: "(INR 250 * 2 hours)" },
    { title: "Additional Players total", value: "(4 * INR 100)" },
    { title: "Subtotal", value: "INR 300.00" },
  ];

  return (
    <div className="container">
      <div className="content">
        <section className="booking-steps py-30">
          <div className="container">
            <ul className="d-lg-flex justify-content-center align-items-center">
              {bookingSteps.map((step, index) => (
                <li key={index} className={index === 1 ? "active" : ""}>
                  <h5>
                    <a href="#">
                      <span>{step.step}</span>
                      {step.label}
                    </a>
                  </h5>
                </li>
              ))}
            </ul>
          </div>
        </section>

        <div className="content book-cage">
          <div className="container">
            <section className="card mb-40">
              <div className="text-center mb-40">
                <h3 className="mb-1">Order Confirmation</h3>
                <p className="sub-title mb-0">
                  Thank you for your order! We're excited to fulfill it with
                  care and efficiency.
                </p>
              </div>
              <div className="master-academy dull-whitesmoke-bg card">
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="d-sm-flex justify-content-start align-items-center">
                      <a href="#">
                        <img
                          className="corner-radius-10"
                          src="assets/img/master-academy.png"
                          alt="Venue"
                        />
                      </a>
                      <div className="info">
                        <div className="d-flex justify-content-start align-items-center mb-3">
                          <span className="text-white dark-yellow-bg color-white me-2 d-flex justify-content-center align-items-center">
                            4.5
                          </span>
                          <span>300 Reviews</span>
                        </div>
                        <h3 className="mb-2">Manchester Academy</h3>
                        <p>
                          Manchester Academy: Where dreams meet excellence in
                          sports education and training.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <ul className="d-sm-flex align-items-center justify-content-evenly">
                      <li>
                        <h3 className="d-inline-block">INR 1500</h3>
                        <span>/hr</span>
                        <p>up to 1 Players</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section className="card booking-order-confirmation">
              <h5 className="mb-3">Booking Details</h5>
              <ul className="booking-info d-lg-flex justify-content-between align-items-center">
                {bookingDetails.map((detail, index) => (
                  <li key={index}>
                    <h6>{detail.title}</h6>
                    <p>{detail.value}</p>
                  </li>
                ))}
              </ul>
              <h5 className="mb-3">Contact Information</h5>
              <ul className="contact-info d-lg-flex justify-content-start align-items-center">
                {contactInformation.map((contact, index) => (
                  <li key={index}>
                    <h6>{contact.title}</h6>
                    <p>
                      {contact.title === "Contact Email Address" ? (
                        <a href="#" className="__cf_email__">
                          {contact.value}
                        </a>
                      ) : (
                        contact.value
                      )}
                    </p>
                  </li>
                ))}
              </ul>
              <h5 className="mb-3">Payment Information</h5>
              <ul className="payment-info d-lg-flex justify-content-start align-items-center">
                {paymentInformation.map((payment, index) => (
                  <li key={index}>
                    <h6>{payment.title}</h6>
                    <p className="primary-text">{payment.value}</p>
                  </li>
                ))}
              </ul>
            </section>
            <div className="text-center btn-row">
              <a className="btn btn-primary me-3 btn-icon" href="/booknow">
                <i className="feather-arrow-left-circle me-1"></i> Back
              </a>
              <a className="btn btn-secondary btn-icon" href="/payment">
                Next <i className="feather-arrow-right-circle ms-1"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;
