import React, { useState, useEffect } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";
import "./Myprofile.css";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import Othersetting from "../Profileedit/Othersetting";

export default function ProfilePage() {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    setUserData(user || {});
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem("user");
    window.location.href = "/signin";
  };

  const handleProfileEdit = () => {
    navigate("/profileedit");
  };

  return (
    <>
      <Header />

      <section className="myprofile-section">
        <MDBContainer className="py-5">
          <MDBRow>
            <MDBCol lg="4">
              <MDBCard className="mb-4">
                <MDBCardBody className="text-center">
                  <MDBCardImage
                    src="assets/Myprofile/samplepp.png"
                    alt="avatar"
                    className="rounded-circle avatar-image"
                    fluid
                  />
                  <p className="text-muted mb-1">{userData.user_name}</p>
                  <div className="d-flex-center mb-2">
                    <MDBBtn
                      outline
                      color="success"
                      className="msb-1"
                      onClick={handleProfileEdit}
                    >
                      Profile Edit
                    </MDBBtn>{" "}
                    <MDBBtn
                      outline
                      color="danger"
                      className="msb-1"
                      onClick={handleLogout}
                    >
                      Logout
                    </MDBBtn>
                  </div>
                </MDBCardBody>
              </MDBCard>

              <MDBCard className="mb-4 mb-lg-0">
                <MDBCardBody className="p-0">
                  <MDBListGroup className="rounded-3">
                    <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                      <a
                        href="/mybookings"
                        className="d-flex align-items-center text-decoration-none"
                      >
                        <MDBIcon fas icon="user fa-lg icon-warning" />
                        <MDBCardText className="ms-3">My Bookings</MDBCardText>
                      </a>
                    </MDBListGroupItem>
                    <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                      <a
                        href="/changepassword"
                        className="d-flex align-items-center text-decoration-none"
                      >
                        <MDBIcon fas icon="key fa-lg icon-info" />
                        <MDBCardText className="ms-3">
                          Manage Password
                        </MDBCardText>
                      </a>
                    </MDBListGroupItem>
                    <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                      <a
                        href="contactus"
                        className="d-flex align-items-center text-decoration-none"
                      >
                        <MDBIcon fas icon="life-ring fa-lg icon-help" />
                        <MDBCardText className="ms-3">
                          Help and Support
                        </MDBCardText>
                      </a>
                    </MDBListGroupItem>
                    <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                      <a
                        href=""
                        className="d-flex align-items-center text-decoration-none"
                      >
                        <MDBIcon fas icon="gift fa-lg icon-primary" />
                        <MDBCardText className="ms-3">
                          Invite and Earn
                        </MDBCardText>
                      </a>
                    </MDBListGroupItem>
                  </MDBListGroup>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol lg="8">
              <MDBCard className="mb-4">
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol sm="3">
                      <MDBCardText>Full Name</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="9">
                      <MDBCardText className="text-muted">
                        {userData.user_name}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="3">
                      <MDBCardText>Email</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="9">
                      <MDBCardText className="text-muted">
                        {userData.email}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="3">
                      <MDBCardText>Mobile</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="9">
                      <MDBCardText className="text-muted">
                        {userData.mobile}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="3">
                      <MDBCardText>Custom User ID</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="9">
                      <MDBCardText className="text-muted">
                        {userData.custom_user_id}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="3">
                      <MDBCardText>Address</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="9">
                      <MDBCardText className="text-muted">
                        {userData.address}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>

              <MDBRow>
                <MDBCol md="6">
                  <MDBCard className="mb-4 mb-md-0">
                    <MDBCardBody>
                      <MDBCardText className="mb-4">
                        <span className="text-primary font-italic me-1">
                          Upcoming
                        </span>
                        [ lawnlink ]
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>

                <MDBCol md="6">
                  <MDBCard className="mb-4 mb-md-0">
                    <MDBCardBody>
                      <MDBCardText className="mb-4">
                        <span className="text-primary font-italic me-1">
                          Upcoming
                        </span>
                        [ lawnlink ]
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
      <Othersetting></Othersetting>
      <Footer />
    </>
  );
}
