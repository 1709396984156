import React from "react";

const appointment = {
  courtName: "Standard Synthetic Court 1",
  appointmentDate: "Mon, Jul 11",
  startTime: "05:25 AM",
  endTime: "06:25 AM",
  additionalGuests: 4,
  location: "Chennai",
  imgSrc: "assets/img/booking/booking-01.jpg",
};

const AppointmentCard = () => {
  return (
    <div className="content">
      <div className="container">
        <div cla  ssName="row">
          <div className="col-lg-12">
            <div className="card dashboard-card">
              <div className="card-header">
                <h4>Today's Matches</h4>
                <p>Your Personal Schedule</p>
              </div>
              <div className="appointment-info">
                <ul>
                  <li>
                    <div className="appointment-item">
                      <div className="appointment-img">
                        <img src={appointment.imgSrc} alt="Appointment" />
                      </div>
                      <div className="appointment-content">
                        <h6>Court Name</h6>
                        <p>{appointment.courtName}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <h6>Appointment Date</h6>
                    <p>{appointment.appointmentDate}</p>
                  </li>
                  <li>
                    <h6>Start Time</h6>
                    <p>{appointment.startTime}</p>
                  </li>
                  <li>
                    <h6>Appointment End Time</h6>
                    <p>{appointment.endTime}</p>
                  </li>
                  <li>
                    <h6>Total Players</h6>
                    <p>{appointment.additionalGuests}</p>
                  </li>
                  <li>
                    <h6>Location</h6>
                    <p>{appointment.location}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentCard;
