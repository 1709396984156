import { useEffect, useState } from "react";
import "./Header.css";
import { Navigate, useNavigate } from "react-router-dom";

const Header = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const navvigateTo = (url) => {
    if (userData != null) {
      navigate(url);
    } else {
      navigate("/signin");
    }
  };

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    setUserData(user);
  }, []);

  return (
    <header className="header header-trans new-header-trans">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg header-nav">
          <div className="navbar-header">
            <a id="mobile_btn" href="#">
              <span className="bar-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </a>
            <a
              href=""
              onClick={() => navvigateTo("/home")}
              className="navbar-brand logo"
            >
              <img
                src="Listimg/Lawnlogo.jpeg"
                className="img-fluid lawn-logo"
                alt="Logo"
              />
            </a>
          </div>
          <div className="main-menu-wrapper">
            <div className="menu-header">
              <a href="#" className="menu-logo">
                <img
                  src="Listimg/Lawnlogo.jpeg"
                  className="img-fluid lawn-logo"
                  alt="Logo"
                />
              </a>
              <a id="menu_close" className="menu-close" href="#">
                <i className="fas fa-times"></i>
              </a>
            </div>
            <ul className="main-nav">
              <li className="active">
                <a href="/home">Home</a>
              </li>

              <li className="has-submenu">
                <a href="#">
                  Turf <i className="fas fa-chevron-down"></i>
                </a>
                <ul className="submenu">
                  <li>
                    <a href="/shopping">Turf List</a>
                  </li>
                  <li>
                    <a href="/hourlypackage">Hourly Package</a>
                  </li>
                  <li>
                    <a href="/availableoffers">Available Offers</a>
                  </li>
                </ul>
              </li>
              {/* <li>
                <a href="" onClick={() => navvigateTo("/turfhost")}>
                  Bookings
                </a>
              </li> */}

              <li className="has-submenu">
                <a href="#">
                  Bookings <i className="fas fa-chevron-down"></i>
                </a>
                <ul className="submenu">
                  <li>
                    <a href="" onClick={() => navvigateTo("/userdashboard")}>
                      User Dashboard
                    </a>
                  </li>
                  <li>
                    <a href="" onClick={() => navvigateTo("/mybookings")}>
                      My Bookings
                    </a>
                  </li>
                  {/* <li>
                    <a href="/turfdetails">About the Turf</a>
                  </li>
                  <li>
                    <a href="" onClick={() => navvigateTo("/invoices")}>
                      Invoice
                    </a>
                  </li>
                  <li>
                    <a href="" onClick={() => navvigateTo("/Profileedit")}>
                      Profile Edit
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      onClick={() => navvigateTo("/Profileedit#changepassword")}
                    >
                      Change Password
                    </a>
                  </li>
                  <li>
                    <a href="" onClick={() => navvigateTo("/Profileedit")}>
                      Other Settings
                    </a>
                  </li> */}
                </ul>
              </li>

              <li className="has-submenu">
                <a href="#">
                  Explore <i className="fas fa-chevron-down"></i>
                </a>
                <ul className="submenu">
                  {/* <li>
                    <a href="/aboutus">About Us</a>
                  </li> */}
                  <li>
                    <a href="/aboutushome">Our Team</a>
                  </li>
                  <li>
                    <a href="/events">Events</a>
                  </li>
                  {/* <li className="has-submenu">
                    <a href="#">Authentication</a>
                    <ul className="submenu">
                      <li>
                        <a href="/signup">Signup</a>
                      </li>
                      <li>
                        <a href="/signin">Signin</a>
                      </li>
                      <li>
                        <a href="" onClick={() => navvigateTo("/profileedit")}>
                          Reset Password
                        </a>
                      </li>
                    </ul>
                  </li> */}
                  <li>
                    <a href="/faq">FAQ</a>
                  </li>
                  {/* <li>
                    <a href="/gallery">Gallery</a>
                  </li> */}
                  <li>
                    <a href="/termsandconditions">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="/privacypolicy">Privacy Policy</a>
                  </li>
                </ul>
              </li>
              {/* <li className="has-submenu">
                <a href="#">
                  Blog <i className="fas fa-chevron-down"></i>
                </a>
                <ul className="submenu">
                  <li>
                    <a href="bloglist">Blog List</a>
                  </li>
                  <li>
                    <a href="blogdetails">Blog Details</a>
                  </li>
                </ul>
              </li> */}

              <li className="has-submenu">
                <a href="#">
                  Match Host <i className="fas fa-chevron-down"></i>
                </a>
                <ul className="submenu">
                  <li>
                    <a href="" onClick={() => navvigateTo("/turfhost")}>
                      Turf
                    </a>
                  </li>
                  <li>
                    <a href="" onClick={() => navvigateTo("/academyhost")}>
                      Academy
                    </a>
                  </li>
                  <li>
                    <a href="" onClick={() => navvigateTo("/groundhost")}>
                      Ground
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="contactus">Contact Us</a>
              </li>
            </ul>
          </div>

          <ul className="nav header-navbar-rht">
            <li className="nav-item">
              {userData ? (
                <div className="nav-link btn btn-white log-register">
                  <a href="/myprofile">
                    <span>
                      <i className="feather-user"></i>
                    </span>
                    {userData.username}
                  </a>
                </div>
              ) : (
                <div className="nav-link btn btn-white log-register">
                  <a href="/signin">
                    <span>
                      <i className="feather-users"></i>
                    </span>
                    Login
                  </a>
                  / <a href="/signup">Register</a>
                </div>
              )}
            </li>

            {/* <li className="nav-item">
              <a className="nav-link btn btn-secondary" href="/availableoffers">
                <span>
                  <i className="feather-check-circle"></i>
                </span>
                Offer Zone
              </a>
            </li> */}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
