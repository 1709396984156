import React from "react";
import "aos/dist/aos.css";
import { Clock, MapPin } from "react-feather";
import "./Offersgrid.css";

const offers = [
  {
    id: 1,
    imgSrc: "assets/offergrid/offergrid01.jpeg",
    discount: "20",
    discountType: "% Flat",
    time: "00:00",
    location: "Chennai",
    title: "Ultimate Turf Showdown",
  },
  {
    id: 2,
    imgSrc: "assets/offergrid/offergrid02.jpeg",
    discount: "20",
    discountType: "% Flat",
    time: "00:00",
    location: "Chennai",
    title: "Ultimate Turf Showdown",
  },
  {
    id: 3,
    imgSrc: "assets/offergrid/offergrid03.jpeg",
    discount: "20",
    discountType: "% Flat",
    time: "00:00",
    location: "Chennai",
    title: "Ultimate Turf Showdown",
  },
  {
    id: 4,
    imgSrc: "assets/offergrid/offergrid04.jpeg",
    discount: "20",
    discountType: "% Flat",
    time: "00:00",
    location: "Chennai",
    title: "Ultimate Turf Showdown",
  },
  {
    id: 5,
    imgSrc: "assets/offergrid/offergrid05.jpeg",
    discount: "20",
    discountType: "% Flat",
    time: "00:00",
    location: "Chennai",
    title: "Ultimate Turf Showdown",
  },
  {
    id: 6,
    imgSrc: "assets/offergrid/offergrid06.jpeg",
    discount: "20",
    discountType: "% Flat",
    time: "00:00",
    location: "Chennai",
    title: "Ultimate Turf Showdown",
  },
  {
    id: 7,
    imgSrc: "assets/offergrid/offergrid07.jpeg",
    discount: "20",
    discountType: "% Flat",
    time: "00:00",
    location: "Chennai",
    title: "Ultimate Turf Showdown",
  },
  {
    id: 8,
    imgSrc: "assets/offergrid/offergrid08.jpeg",
    discount: "20",
    discountType: "% Flat",
    time: "00:00",
    location: "Chennai",
    title: "Ultimate Turf Showdown",
  },
];

const OfferGrid = () => {
  return (
    <div className="content new-offersgrid">
      <div className="container">
        <section className="services">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              Save <span>BIG</span>
            </h2>
          </div>
          <div className="row">
            {offers.map((offer) => (
              <div
                className="col-12 col-sm-12 col-md-6 col-lg-3"
                key={offer.id}
              >
                <div className="listing-item">
                  <div className="listing-img">
                    <a href="/booknow">
                      <img
                        src={offer.imgSrc}
                        className="img-fluid"
                        alt="Event"
                      />
                    </a>
                    <div className="date-info text-center">
                      <h2>{offer.discount}</h2>
                      <h6>{offer.discountType}</h6>
                    </div>
                  </div>
                  <div className="listing-content">
                    <ul className="d-flex justify-content-start align-items-center">
                      <li>
                        <Clock />
                        {offer.time}
                      </li>
                      <li>
                        <MapPin />
                        {offer.location}
                      </li>
                    </ul>
                    <h4 className="listing-title">
                      <a href="#">{offer.title}</a>
                    </h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default OfferGrid;
