import React from "react";
import "aos/dist/aos.css";
import { Heart, MapPin, Calendar, Eye } from "react-feather";
import "./Turflist.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const VenueGrid = () => {
  const venues = [
    {
      id: 1,
      imgSrc: "Listimg/list01.avif",
      tag: "Premium",
      rating: "4.2",
      reviews: "300 Reviews",
      price: "INR 2500 /hr",
      title: "GreenField Turf Solutions",
      address: "chennai",
      description:
        "Certified Turf with a deep understanding of the sport's techniques and strategies.",
      nextAvailability: "20 May 2023",
    },
    {
      id: 2,
      imgSrc: "Listimg/list02.avif",
      tag: "Premium",
      rating: "4.2",
      reviews: "300 Reviews",
      price: "INR 2500 /hr",
      title: "GreenField Turf Solutions",
      address: "chennai",
      description:
        "Certified Turf with a deep understanding of the sport's techniques and strategies.",
      nextAvailability: "20 May 2023",
    },
    {
      id: 3,
      imgSrc: "Listimg/list03.avif",
      tag: "Premium",
      rating: "4.2",
      reviews: "300 Reviews",
      price: "INR 2500 /hr",
      title: "GreenField Turf Solutions",
      address: "madurai",
      description:
        "Certified Turf with a deep understanding of the sport's techniques and strategies.",
      nextAvailability: "20 May 2023",
    },
    {
      id: 4,
      imgSrc: "Listimg/list04.avif",
      tag: "Premium",
      rating: "4.2",
      reviews: "300 Reviews",
      price: "INR 2500 /hr",
      title: "GreenField Turf Solutions",
      address: "thanjavur",
      description:
        "Certified Turf with a deep understanding of the sport's techniques and strategies.",
      nextAvailability: "20 May 2023",
    },
    {
      id: 5,
      imgSrc: "Listimg/list05.avif",
      tag: "Premium",
      rating: "4.2",
      reviews: "300 Reviews",
      price: "INR 2500 /hr",
      title: "GreenField Turf Solutions",
      address: "thanjavur",
      description:
        "Certified Turf with a deep understanding of the sport's techniques and strategies.",
      nextAvailability: "20 May 2023",
    },
    {
      id: 6,
      imgSrc: "Listimg/list06.avif",
      tag: "Premium",
      rating: "4.2",
      reviews: "300 Reviews",
      price: "INR 2500 /hr",
      title: "GreenField Turf Solutions",
      address: "salem",
      description:
        "Certified Turf with a deep understanding of the sport's techniques and strategies.",
      nextAvailability: "20 May 2023",
    },
    {
      id: 7,
      imgSrc: "Listimg/list07.avif",
      tag: "Premium",
      rating: "4.2",
      reviews: "300 Reviews",
      price: "INR 2500 /hr",
      title: "GreenField Turf Solutions",
      address: "salem",
      description:
        "Certified Turf with a deep understanding of the sport's techniques and strategies.",
      nextAvailability: "20 May 2023",
    },
    {
      id: 8,
      imgSrc: "Listimg/list08.avif",
      tag: "Premium",
      rating: "4.2",
      reviews: "300 Reviews",
      price: "INR 2500 /hr",
      title: "GreenField Turf Solutions",
      address: "salem",
      description:
        "Certified Turf with a deep understanding of the sport's techniques and strategies.",
      nextAvailability: "20 May 2023",
    },
    // Add more venues here if needed
  ];
  const [filteredVenues, setFilteredVenues] = useState(venues);
  const [userData, setUserData] = useState(null);
  const [category, setCategory] = useState("all");
  const [location, setLocation] = useState("all");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const navigate = useNavigate();

  const navvigateTo = (url) => {
    navigate(url);
  };
  const handlesearch = (event) => {
    event.preventDefault();
    debugger;
    const category = document.getElementsByName("category")[0].value;
    const location = document.getElementsByName("location")[0].value;
    sessionStorage.setItem("location", location);
    sessionStorage.setItem("category", category);
    if (category == "turfs") {
      setLocation(location);
    } else {
      navigate("/events");
    }
  };

  const handleclear = (event) => {
    setCategory("all");
    setLocation("all");
    sessionStorage.removeItem("category");
    sessionStorage.removeItem("location");
    setButtonDisabled(true);
  };

  useEffect(() => {
    var category = sessionStorage.getItem("category");
    setCategory(category);

    var location = sessionStorage.getItem("location");
    if (location != "" && location != null) {
      setLocation(location);
    }
    var user = JSON.parse(sessionStorage.getItem("user"));
    setUserData(user);
  }, []);

  useEffect(() => {
    if (location != "all") {
      const filteredVenues = venues.filter((x) => x.address === location);
      setFilteredVenues(filteredVenues);
    } else {
      setFilteredVenues(venues);
    }
  }, [location]);
  return (
    <>
      <div className="content">
        <div className="container">
          <div className="container new-turflist-container">
            {/* <div className="search-box">
              <form onSubmit={handlesearch}>
                <div className="search-input line">
                  <div className="form-group mb-0 search-input">
                    <label>Search for</label>
                    <select
                      className="select"
                      // value={category}
                      name="category"
                      id="category"
                    >
                      <option value="">Choose Category</option>
                      <option value="turfs">Turfs</option>
                      <option value="events">Events</option>
                    </select>
                  </div>
                </div>
                <div className="search-input">
                  <div className="form-group mb-0">
                    <label>Where </label>
                    <select
                      // value={location}
                      className="select"
                      id="location"
                      name="location"
                    >
                      <option value="">Choose Location</option>
                      <option value="all">All</option>

                      <option value="chennai">Chennai</option>
                      <option value="madurai">Madurai</option>
                      <option value="thanjavur">Thanjavur</option>
                      <option value="salem">Salem</option>
                      <option value="coimbatore">Coimbatore</option>
                    </select>
                  </div>
                </div>
                <div className="search-btn">
                  <button className="btn" type="submit">
                    <i className="feather-search"></i>
                    <span className="search-text">Search</span>
                  </button>
                </div>
              </form>
            </div> */}
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              onClick={handleclear}
              disabled={buttonDisabled}
            ></button>

            <div className="row">
              <div className="col-lg-12">
                {filteredVenues.map((venue) => (
                  <div className="featured-venues-item" key={venue.id}>
                    <div className="listing-item listing-item-grid coach-listview">
                      <div className="listing-img">
                        <a href="/booknow">
                          <img src={venue.imgSrc} alt="Venue" />
                        </a>
                        <div className="fav-item-venues">
                          <span className="tag tag-blue">{venue.tag}</span>
                          <div className="list-reviews coche-star">
                            {/* <a href="#" className="fav-icon">
                              <Heart />
                            </a> */}
                          </div>
                        </div>
                      </div>
                      <div className="listing-content">
                        <div className="list-reviews near-review near-review-list">
                          <div className="d-flex align-items-center">
                            <span className="rating-bg">{venue.rating}</span>
                            <span>{venue.reviews}</span>
                          </div>
                          <span className="mile-away">
                            <span>From</span> {venue.price}
                          </span>
                        </div>
                        <h3 className="listing-title">
                          <h4 href="#">{venue.title}</h4>
                        </h3>
                        <ul className="mb-2">
                          <li>
                            <span>
                              <MapPin className="me-2" /> {venue.address}
                            </span>
                          </li>
                        </ul>
                        <div className="listing-details-group">
                          <p>{venue.description}</p>
                        </div>
                        <div className="avalbity-review avalbity-review-list">
                          <ul>
                            <li>
                              {/* <div className="avalibity-date">
                                <span>
                                  <Calendar />
                                </span>
                                <div className="avalibity-datecontent">
                                  <h6>Next Availability</h6>
                                  <h5>{venue.nextAvailability}</h5>
                                </div>
                              </div> */}
                            </li>
                          </ul>
                          <ul className="More-coache-list">
                            <li>
                              <a
                                href="/turfdetails"
                                className="btn btn-primary w-100"
                              >
                                <Eye className="me-2" /> View
                              </a>
                            </li>
                            <li>
                              <a
                                href=""
                                onClick={() => {
                                  navvigateTo("/booknow");
                                }}
                                className="btn btn-secondary w-100"
                              >
                                <Calendar className="me-2" /> Book Now
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VenueGrid;
