import React from "react";

const statistics = [
  { value: "78", label: "Total Turfs Booked", icon: "statistics-01.svg" },
  { value: "45", label: "Total Turfs Upcoming", icon: "statistics-02.svg" },
  { value: "06", label: "Total Cancellled", icon: "statistics-03.svg" },
  { value: "INR 45,056", label: "Total Payments", icon: "statistics-04.svg" },
];

const StatisticsCard = () => {
  return (
    <div className="content">
      <div className="container">
        <div className="row container">
          <div className="col-lg-12">
            <div className="card dashboard-card statistics-card">
              <div className="card-header">
                <h4>Statistics</h4>
                <p>Boost your game with stats and goals tailored to you</p>
              </div>
              <div className="row">
                {statistics.map((stat, index) => (
                  <div
                    key={index}
                    className="col-xl-3 col-lg-6 col-md-6 d-flex"
                  >
                    <div className="statistics-grid flex-fill">
                      <div className="statistics-content">
                        <h3>{stat.value}</h3>
                        <p>{stat.label}</p>
                      </div>
                      <div className="statistics-icon">
                        <img src={`assets/img/icons/${stat.icon}`} alt="Icon" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsCard;
