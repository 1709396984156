import React from "react";

const Worksection = () => {
  const workSteps = [
    {
      id: 1,
      iconSrc: "assets/img/icons/work-icon1.svg",
      title: "Join Us",
      description:
        "Quick and Easy Registration: Get started on our software platform with a simple account creation process.",
      buttonText: "Register Now",
      buttonLink: "/signup",
    },
    {
      id: 2,
      iconSrc: "assets/img/icons/work-icon2.svg",
      title: "Select Turfs/Venues",
      description:
        "Book Premium Turfs and venues for expert guidance and premium facilities.",
      buttonText: "Go To Turfs",
      buttonLink: "/shopping",
    },
    {
      id: 3,
      iconSrc: "assets/img/icons/work-icon3.svg",
      title: "Booking Process",
      description:
        "Easily book, pay, and enjoy a seamless experience on our user-friendly platform.",
      buttonText: "Book Now",
      buttonLink: "/hourlypackage",
    },
  ];

  return (
    <section className="section work-section">
      <div className="work-cock-img">
        <img src="assets/img/icons/work-cock.svg" alt="Icon" />
      </div>
      <div className="work-img">
        <div className="work-img-right">
          <img src="assets/img/bg/work-bg.png" alt="Icon" />
        </div>
      </div>
      <div className="container">
        <div className="section-heading aos" data-aos="fade-up">
          <h2>
            How It <span>Works</span>
          </h2>
          <p className="sub-title">
            Simplifying the booking process for Turf, venues, and Offers.
          </p>
        </div>
        <div className="row justify-content-center">
          {workSteps.map(
            ({ id, iconSrc, title, description, buttonText, buttonLink }) => (
              <div key={id} className="col-lg-4 col-md-6 d-flex">
                <div className="work-grid w-100 aos" data-aos="fade-up">
                  <div className="work-icon">
                    <div className="work-icon-inner">
                      <img src={iconSrc} alt="Icon" />
                    </div>
                  </div>
                  <div className="work-content">
                    <h5>
                      <a href={buttonLink}>{title}</a>
                    </h5>
                    <p>{description}</p>
                    <a className="btn" href={buttonLink}>
                      {buttonText} <i className="feather-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default Worksection;
