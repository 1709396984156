const Bookinglist = () => {
  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="court-tab-content">
                <div className="card card-tableset">
                  <div className="card-body">
                    <div className="coache-head-blk">
                      <div className="row align-items-center">
                        <div className="col-md-5">
                          <div className="court-table-head">
                            <h4>My Bookings</h4>
                            <p>
                              Manage and track all your upcoming Turf's
                              bookings.
                            </p>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="table-search-top">
                            <div id="tablefilter"></div>
                            <div className="request-coach-list">
                              <div className="card-header-btns">
                                <nav>
                                  <div className="nav nav-tabs" role="tablist">
                                    <button
                                      className="nav-link active"
                                      id="nav-Recent-tab"
                                      data-bs-toggle="tab"
                                      data-bs-target="#nav-Recent"
                                      type="button"
                                      role="tab"
                                      aria-controls="nav-Recent"
                                      aria-selected="true"
                                    >
                                      Turf's
                                    </button>
                                  </div>
                                </nav>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="nav-Recent"
                        role="tabpanel"
                        aria-labelledby="nav-Recent-tab"
                        tabIndex="0"
                      >
                        <div className="table-responsive table-datatble">
                          <table className="table table1">
                            <thead className="thead-light">
                              <tr>
                                {/* <th>Court Name</th> */}
                                <th>Date & Time </th>
                                <th>Payment</th>
                                <th>Status</th>
                                <th>Details</th>
                                {/* <th>Chat</th> */}
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {/* <td>
                                  <h2 className="table-avatar">
                                    <a
                                      href="#"
                                      className="avatar avatar-sm flex-shrink-0"
                                    >
                                      <img
                                        className="avatar-img"
                                        src="assets/img/booking/booking-01.jpg"
                                        alt="User"
                                      />
                                    </a>
                                    <span className="table-head-name flex-grow-1">
                                      <a
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#profile-court"
                                      >
                                        Leap Sports Academy
                                      </a>
                                      <span>Court 1 </span>
                                    </span>
                                  </h2>
                                </td> */}
                                <td className="table-date-time">
                                  <h4>
                                    Mon, Jul 11<span>06:00 PM - 08:00 PM</span>
                                  </h4>
                                </td>
                                <td>
                                  <span className="pay-dark fs-16">
                                    INR 120
                                  </span>
                                </td>
                                <td>
                                  <span className="badge bg-success">
                                    <i className="feather-check-square me-1"></i>
                                    Accepted
                                  </span>
                                </td>
                                <td className="text-pink view-detail-pink">
                                  <a
                                    href="#:;"
                                    data-bs-toggle="modal"
                                    data-bs-target="#upcoming-court"
                                  >
                                    <i className="feather-eye"></i>View Details
                                  </a>
                                </td>
                                {/* <td>
                                  <a href="#">
                                    <img
                                      src="assets/img/icons/chat-icon-01.svg"
                                      alt="Chat"
                                      className="me-2"
                                    />
                                    Chat
                                  </a>
                                </td> */}
                                <td className="text-end">
                                  <div className="dropdown dropdown-action table-drop-action">
                                    <a
                                      href="#"
                                      className="action-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-h"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#cancel-court"
                                      >
                                        <i className="feather-corner-down-left"></i>
                                        Cancel Booking
                                      </a>
                                      {/*<a className="dropdown-item" href="#">
                                        <i className="feather-edit"></i>Edit
                                      </a>
                                      <a className="dropdown-item" href="#">
                                        <i className="feather-trash"></i>Delete
                                      </a>*/}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                {/* <td>
                                  <h2 className="table-avatar">
                                    <a
                                      href="#"
                                      className="avatar avatar-sm flex-shrink-0"
                                    >
                                      <img
                                        className="avatar-img"
                                        src="assets/img/booking/booking-02.jpg"
                                        alt="User"
                                      />
                                    </a>
                                    <span className="table-head-name flex-grow-1">
                                      <a
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#profile-court"
                                      >
                                        Feather Badminton
                                      </a>
                                      <span>Court 1 </span>
                                    </span>
                                  </h2>
                                </td> */}
                                <td className="table-date-time">
                                  <h4>
                                    Mon, Jul 12<span>02:00 PM - 05:00 PM</span>
                                  </h4>
                                </td>
                                <td>
                                  <span className="pay-dark fs-16">INR 90</span>
                                </td>
                                <td>
                                  <span className="badge bg-info">
                                    <i className="feather-clock me-1"></i>
                                    Awaiting
                                  </span>
                                </td>
                                <td className="text-pink view-detail-pink">
                                  <a
                                    href="#:;"
                                    data-bs-toggle="modal"
                                    data-bs-target="#upcoming-court"
                                  >
                                    <i className="feather-eye"></i>View Details
                                  </a>
                                </td>
                                {/* <td>
                                  <a href="#">
                                    <img
                                      src="assets/img/icons/chat-icon-01.svg"
                                      alt="Chat"
                                      className="me-2"
                                    />
                                    Chat
                                  </a>
                                </td> */}
                                <td className="text-end">
                                  <div className="dropdown dropdown-action table-drop-action">
                                    <a
                                      href="#"
                                      className="action-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-h"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#cancel-court"
                                      >
                                        <i className="feather-corner-down-left"></i>
                                        Cancel Booking
                                      </a>
                                      {/*<a className="dropdown-item" href="#">
                                        <i className="feather-edit"></i>Edit
                                      </a>
                                      <a className="dropdown-item" href="#">
                                        <i className="feather-trash"></i>Delete
                                      </a>*/}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                {/* <td>
                                  <h2 className="table-avatar">
                                    <a
                                      href="#"
                                      className="avatar avatar-sm  flex-shrink-0"
                                    >
                                      <img
                                        className="avatar-img"
                                        src="assets/img/booking/booking-03.jpg"
                                        alt="User"
                                      />
                                    </a>
                                    <span className="table-head-name flex-grow-1">
                                      <a
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#profile-court"
                                      >
                                        Bwing Sports Academy
                                      </a>
                                      <span>Court 1 </span>
                                    </span>
                                  </h2>
                                </td> */}
                                <td className="table-date-time">
                                  <h4>
                                    Mon, Jul 15<span>03:00 PM - 05:00 PM</span>
                                  </h4>
                                </td>
                                <td>
                                  <span className="pay-dark fs-16">
                                    INR 130
                                  </span>
                                </td>
                                <td>
                                  <span className="badge bg-info">
                                    <i className="feather-clock me-1"></i>
                                    Awaiting
                                  </span>
                                </td>
                                <td className="text-pink view-detail-pink">
                                  <a
                                    href="#:;"
                                    data-bs-toggle="modal"
                                    data-bs-target="#upcoming-court"
                                  >
                                    <i className="feather-eye"></i>View Details
                                  </a>
                                </td>
                                {/* <td>
                                  <a href="#">
                                    <img
                                      src="assets/img/icons/chat-icon-01.svg"
                                      alt="Chat"
                                      className="me-2"
                                    />
                                    Chat
                                  </a>
                                </td> */}
                                <td className="text-end">
                                  <div className="dropdown dropdown-action table-drop-action">
                                    <a
                                      href="#"
                                      className="action-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-h"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#cancel-court"
                                      >
                                        <i className="feather-corner-down-left"></i>
                                        Cancel Booking
                                      </a>
                                      {/*<a className="dropdown-item" href="#">
                                        <i className="feather-edit"></i>Edit
                                      </a>
                                      <a className="dropdown-item" href="#">
                                        <i className="feather-trash"></i>Delete
                                      </a>*/}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                {/* <td>
                                  <h2 className="table-avatar">
                                    <a
                                      href="#"
                                      className="avatar avatar-sm  flex-shrink-0"
                                    >
                                      <img
                                        className="avatar-img"
                                        src="assets/img/booking/booking-04.jpg"
                                        alt="User"
                                      />
                                    </a>
                                    <span className="table-head-name flex-grow-1">
                                      <a
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#profile-court"
                                      >
                                        Marsh Academy
                                      </a>
                                      <span>Court 1 </span>
                                    </span>
                                  </h2>
                                </td> */}
                                <td className="table-date-time">
                                  <h4>
                                    Mon, Jul 16<span>05:00 PM - 07:00 PM</span>
                                  </h4>
                                </td>
                                <td>
                                  <span className="pay-dark fs-16">
                                    INR 100
                                  </span>
                                </td>
                                <td>
                                  <span className="badge bg-info">
                                    <i className="feather-clock me-1"></i>
                                    Awaiting
                                  </span>
                                </td>
                                <td className="text-pink view-detail-pink">
                                  <a
                                    href="#:;"
                                    data-bs-toggle="modal"
                                    data-bs-target="#upcoming-court"
                                  >
                                    <i className="feather-eye"></i>View Details
                                  </a>
                                </td>
                                {/* <td>
                                  <a href="#">
                                    <img
                                      src="assets/img/icons/chat-icon-01.svg"
                                      alt="Chat"
                                      className="me-2"
                                    />
                                    Chat
                                  </a>
                                </td> */}
                                <td className="text-end">
                                  <div className="dropdown dropdown-action table-drop-action">
                                    <a
                                      href="#"
                                      className="action-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-h"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#cancel-court"
                                      >
                                        <i className="feather-corner-down-left"></i>
                                        Cancel Booking
                                      </a>
                                      {/*<a className="dropdown-item" href="#">
                                        <i className="feather-edit"></i>Edit
                                      </a>
                                      <a className="dropdown-item" href="#">
                                        <i className="feather-trash"></i>Delete
                                      </a>*/}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                {/* <td>
                                  <h2 className="table-avatar">
                                    <a
                                      href="#"
                                      className="avatar avatar-sm  flex-shrink-0"
                                    >
                                      <img
                                        className="avatar-img"
                                        src="assets/img/booking/booking-05.jpg"
                                        alt="User"
                                      />
                                    </a>
                                    <span className="table-head-name flex-grow-1">
                                      <a
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#profile-court"
                                      >
                                        Wing Sports Academy
                                      </a>
                                      <span>Court 1 </span>
                                    </span>
                                  </h2>
                                </td> */}
                                <td className="table-date-time">
                                  <h4>
                                    Mon, Jul 16<span>05:00 PM - 08:00 PM</span>
                                  </h4>
                                </td>
                                <td>
                                  <span className="pay-dark fs-16">
                                    INR 140
                                  </span>
                                </td>
                                <td>
                                  <span className="badge bg-info">
                                    <i className="feather-clock me-1"></i>
                                    Awaiting
                                  </span>
                                </td>
                                <td className="text-pink view-detail-pink">
                                  <a
                                    href="#:;"
                                    data-bs-toggle="modal"
                                    data-bs-target="#upcoming-court"
                                  >
                                    <i className="feather-eye"></i>View Details
                                  </a>
                                </td>
                                {/* <td>
                                  <a href="#">
                                    <img
                                      src="assets/img/icons/chat-icon-01.svg"
                                      alt="Chat"
                                      className="me-2"
                                    />
                                    Chat
                                  </a>
                                </td> */}
                                <td className="text-end">
                                  <div className="dropdown dropdown-action table-drop-action">
                                    <a
                                      href="#"
                                      className="action-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-h"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#cancel-court"
                                      >
                                        <i className="feather-corner-down-left"></i>
                                        Cancel Booking
                                      </a>
                                      {/*<a className="dropdown-item" href="#">
                                        <i className="feather-edit"></i>Edit
                                      </a>
                                      <a className="dropdown-item" href="#">
                                        <i className="feather-trash"></i>Delete
                                      </a>*/}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-RecentCoaching"
                        role="tabpanel"
                        aria-labelledby="nav-RecentCoaching-tab"
                        tabIndex="0"
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="tab-footer">
                  <div className="row">
                    <div className="col-md-6">
                      <div id="tablelength"></div>
                    </div>
                    <div className="col-md-6">
                      <div id="tablepage"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Bookinglist;
