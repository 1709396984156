import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";
import "./Bloglist.css";

const blogPosts = [
  {
    id: 1,
    imgSrc: "assets/img/blog/blog-01.jpg",
    imgAlt: "Venue",
    tag: "Badminton",
    authorImg: "assets/img/profiles/avatar-01.jpg",
    authorName: "Orlando Waters",
    date: "15 May 2023",
    title: "Mastering the Badminton Basics: A Guide for Beginners",
    description:
      "Master the fundamentals of badminton with our beginner's guide, unlocking the path to skillful play and enjoyment on the court.",
    readTime: "10 Min To Read",
  },
  {
    id: 2,
    imgSrc: "assets/img/blog/blog-02.jpg",
    imgAlt: "Venue",
    tag: "Sports Activities",
    authorImg: "assets/img/profiles/avatar-06.jpg",
    authorName: "Claire Nichols",
    date: "16 Jun 2023",
    title: "Unleashing Your Badminton Potential: Tips for Skill Development",
    description:
      "Unlock your badminton potential and elevate your skills with our expert tips for effective skill development on the court",
    readTime: "5 Min To Read",
  },
  {
    id: 3,
    imgSrc: "assets/img/blog/blog-03.jpg",
    imgAlt: "Venue",
    tag: "Rules of Game",
    authorImg: "assets/img/profiles/user-04.jpg",
    authorName: "Joanna Le",
    date: "17 May 2023",
    title: "The Art of Footwork: Enhancing Agility in Badminton",
    description:
      "Master the art of footwork and elevate your agility in badminton with our proven techniques and training methods.",
    readTime: "4 Min To Read",
  },
];

const BlogGrid = () => {
  return (
    <>
      <Header></Header>
      <div className="section-heading aos new-title" data-aos="fade-up">
        <h2>
          Blog <span>Lists</span>
        </h2>
      </div>
      <div className="content blog-grid">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-10 col-lg-8 mx-auto">
              {blogPosts.map((post) => (
                <div key={post.id} className="featured-venues-item">
                  <div className="listing-item">
                    <div className="listing-img">
                      <a href="#">
                        <img
                          src={post.imgSrc}
                          className="img-fluid"
                          alt={post.imgAlt}
                        />
                      </a>
                      <div className="fav-item-venues news-sports">
                        <span className="tag tag-blue">{post.tag}</span>
                        <div className="list-reviews coche-star">
                          <a href="#" className="fav-icon">
                            <i className="feather-heart"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="listing-content news-content">
                      <div className="listing-venue-owner">
                        <div className="navigation">
                          <a href="#">
                            <img src={post.authorImg} alt="User" />
                            {post.authorName}
                          </a>
                          <span>
                            <i className="feather-calendar"></i>
                            {post.date}
                          </span>
                        </div>
                      </div>
                      <h3 className="listing-title">
                        <a href="#">{post.title}</a>
                      </h3>
                      <p>{post.description}</p>
                      <div className="listing-button read-new">
                        <span>
                          <img src="assets/img/icons/clock.svg" alt="Icon" />
                          {post.readTime}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </>
  );
};

export default BlogGrid;
