import React from "react";

// Array of image data
const images = [
  { src: "assets/img/aboutus/banner-01.jpg", alt: "Banner-01" },
  { src: "assets/img/aboutus/banner-02.jpg", alt: "Banner-02" },
  { src: "assets/img/aboutus/banner-03.jpg", alt: "Banner-03" },
];

const Aboutusheader = () => {
  return (
    <div className="content aboutus-info">
      <div className="container">
        <div className="row d-flex align-items-center">
          {images.map((image, index) => (
            <div
              key={index}
              className={`col-12 col-sm-${index === 1 ? "6" : "3"} col-md-${
                index === 1 ? "6" : "3"
              } col-lg-${index === 1 ? "6" : "3"}`}
            >
              <div className="banner text-center">
                <img
                  src={image.src}
                  className="img-fluid corner-radius-10"
                  alt={image.alt}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Aboutusheader;
