import Header from "../Home/Header";
import Turfdetailcoursel from "./Turfdetailscoursel";
import Turfinfo from "./Turfinfo";
import Turfdetailcontent from "./Turfdetailcontent";
import Footer from "../Footer/Footer";

const Turfdetails = () => {
  return (
    <>
      <Header></Header>
      <Turfdetailcoursel></Turfdetailcoursel>
      <Turfinfo></Turfinfo>
      <Turfdetailcontent></Turfdetailcontent>
      <Footer></Footer>
    </>
  );
};
export default Turfdetails;
