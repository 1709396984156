import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

// Sample data
const venueDetails = {
  name: "Exson Academy",
  location: "70 Bright St New York, USA",
  phone: "+3 80992 31212",
  email: "email@example.com",
  rating: 5.0,
  reviews: 15,
  price: 150,
  PitchSize: "5 vs 5",
  addedBy: "Shan Dhamu",
};

// Sample past reviews
const pastReviews = [
  { user: "John Doe", rating: 5, comment: "Great venue!" },
  { user: "Jane Smith", rating: 4, comment: "Good experience overall." },
];

const socialOptions = [
  { href: "#share", icon: "feather-share-2", text: "Share" },
];

const VenueInfo = ({ details, onShareClick, onReviewClick }) => (
  <div className="venue-info white-bg d-block">
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
          <h1 className="d-flex align-items-center justify-content-start">
            {details.name}
            <span className="d-flex justify-content-center align-items-center">
              <i className="fas fa-check-double"></i>
            </span>
          </h1>
          <ul className="d-sm-flex justify-content-start align-items-center">
            <li>
              <i className="feather-map-pin"></i>
              {details.location}
            </li>
            <li>
              <i className="feather-phone-call"></i>
              {details.phone}
            </li>
            <li>
              <i className="feather-mail"></i>
              <a href={`mailto:${details.email}`}>{details.email}</a>
            </li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-right">
          <ul className="social-options float-lg-end d-sm-flex justify-content-start align-items-center">
            {socialOptions.map((option, index) => (
              <li key={index}>
                <a href={option.href} onClick={onShareClick}>
                  <i className={option.icon}></i>
                  {option.text}
                </a>
              </li>
            ))}
            <li
              className="venue-review-info d-flex justify-content-start align-items-center"
              onClick={onReviewClick}
            >
              <span className="d-flex justify-content-center align-items-center">
                {details.rating}
              </span>
              <div className="review">
                <div className="rating">
                  {Array.from({ length: details.rating }, (_, i) => (
                    <i key={i} className="fas fa-star filled"></i>
                  ))}
                </div>
                <p className="mb-0">
                  <a href="#">Reviews</a>
                </p>
              </div>
              <i className="fa-regular fa-comments"></i>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="row bottom-row d-flex align-items-center">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
          <ul className="d-sm-flex details">
            <li>
              <div className="profile-pic">
                <a href="#" className="venue-type">
                  <img
                    className="img-fluid"
                    src="assets/img/icons/venue-type.svg"
                    alt="Icon"
                  />
                </a>
              </div>
              <div className="ms-2">
                <p>Pitch Size</p>
                <h6 className="mb-0">{details.PitchSize}</h6>
              </div>
            </li>
            <li>
              <div className="profile-pic">
                <a href="#">
                  <img
                    className="img-fluid"
                    src="assets/img/profiles/avatar-01.jpg"
                    alt="Icon"
                  />
                </a>
              </div>
              <div className="ms-2">
                <p>Added By</p>
                <h6 className="mb-0">{details.addedBy}</h6>
              </div>
            </li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
          <div className="d-flex float-sm-end align-items-center">
            <p className="d-inline-block me-2 mb-0">Starts From :</p>
            <h3 className="primary-text mb-0 d-inline-block">
              ${details.price}
              <span>/ hr</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Turfinfo = () => {
  const [showShareModal, setShowShareModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [review, setReview] = useState({ user: "", rating: 0, comment: "" });

  const handleShareClick = (e) => {
    e.preventDefault();
    setShowShareModal(true);
  };

  const handleCloseShare = () => {
    setShowShareModal(false);
  };

  const handleReviewClick = (e) => {
    e.preventDefault();
    setShowReviewModal(true);
  };

  const handleCloseReview = () => {
    setShowReviewModal(false);
  };

  const handleReviewChange = (e) => {
    const { name, value } = e.target;
    setReview({ ...review, [name]: value });
  };

  const handleReviewSubmit = (e) => {
    e.preventDefault();
    console.log("New review submitted:", review);
    setShowReviewModal(false);
  };

  return (
    <div className="content venue-coach-details">
      <div className="container">
        <VenueInfo
          details={venueDetails}
          onShareClick={handleShareClick}
          onReviewClick={handleReviewClick}
        />
      </div>
      <Modal show={showShareModal} onHide={handleCloseShare}>
        <Modal.Header closeButton>
          <Modal.Title>Share this Venue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="share-options">
            <a
              href={`https://wa.me/?text=Check out this venue: ${venueDetails.name} - ${venueDetails.location}`}
              target="_blank"
              rel="noopener noreferrer"
              className="share-option"
            >
              <i className="fab fa-whatsapp"></i> WhatsApp
            </a>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
              target="_blank"
              rel="noopener noreferrer"
              className="share-option"
            >
              <i className="fab fa-facebook"></i> Facebook
            </a>
            <a
              href={`https://www.instagram.com/?url=${window.location.href}`}
              target="_blank"
              rel="noopener noreferrer"
              className="share-option"
            >
              <i className="fab fa-instagram"></i> Instagram
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseShare}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showReviewModal} onHide={handleCloseReview}>
        <Modal.Header closeButton>
          <Modal.Title>Reviews</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="past-reviews">
            <h5>Past Reviews</h5>
            {pastReviews.map((review, index) => (
              <div key={index} className="review-item">
                <p>
                  <strong>{review.user}</strong>
                </p>
                <div className="rating">
                  {Array.from({ length: review.rating }, (_, i) => (
                    <i key={i} className="fas fa-star filled"></i>
                  ))}
                </div>
                <p>{review.comment}</p>
                <hr />
              </div>
            ))}
          </div>
          <div className="add-review">
            <h5>Add a Review</h5>
            <Form onSubmit={handleReviewSubmit}>
              <Form.Group className="mb-3" controlId="formUser">
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  type="text"
                  name="user"
                  value={review.user}
                  onChange={handleReviewChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formRating">
                <Form.Label>Rating</Form.Label>
                <Form.Control
                  as="select"
                  name="rating"
                  value={review.rating}
                  onChange={handleReviewChange}
                  required
                >
                  <option value="0">Select rating</option>
                  {[...Array(5)].map((_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formComment">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as="textarea"
                  name="comment"
                  value={review.comment}
                  onChange={handleReviewChange}
                  rows={3}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit Review
              </Button>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseReview}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Turfinfo;
