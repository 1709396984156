import "./Underconstruction";

const Underconstruction = () => {
  return (
    <>
      <div class="content ellipses coming-soon-page">
        <div class="container">
          <section class="d-flex justify-content-center align-items-center">
            <div class="row vph-100 d-flex align-items-center">
              <div class="col-sm-10 col-md-10 col-lg-10 mx-auto">
                <header class="text-center ">
                  {/* <a href="#">
                    <img src="" class="img-fluid" alt="Logo" className="" />
                  </a> */}
                </header>

                <div class="text-img">
                  <img
                    src="assets/img/coming-soon.png"
                    class="img-fluid"
                    alt="coming-soon"
                  />
                </div>
                <div class="col-sm-10 col-md-10 col-lg-10 mx-auto text-center">
                  <h3>Great things are coming soon</h3>
                  <p>
                    We are currently woking on new website , We’ll be launching
                    soon
                  </p>
                  <div class="subscribe-style">
                    <div class="subscribe-blk bg-white">
                      <div class="input-group align-items-center">
                        <i class="feather-mail"></i>
                        <input
                          type="email"
                          class="form-control"
                          placeholder="Enter Email Address"
                          aria-label="email"
                        />
                        <div class="subscribe-btn-grp">
                          <input
                            type="submit"
                            class="btn btn-secondary"
                            value="Subscribe"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="play-img">
          <img src="assets/img/men.png" class="img-fluid" alt="Venue" />
        </div>
      </div>
    </>
  );
};
export default Underconstruction;
