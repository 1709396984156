import React from "react";

const Bestservice = () => {
  const stats = [
    {
      title: "Coaches",
      count: 88,
      description:
        "Highly skilled Turfs with extensive expertise in the sport.",
    },
    {
      title: "Courts",
      count: 59,
      description: "Well-maintained courts for optimal badminton experiences.",
    },
  ];

  const faqs = [
    {
      id: "One",
      question: "How can I book a turf at LawnLink?",
      answer:
        "Book your LawnLink turf online or contact our customer service for seamless reservations.",
    },
    {
      id: "Two",
      question: "What is the duration of a turf booking?",
      answer:
        "The duration of a turf booking varies depending on the package selected. Typically, bookings are available in hourly increments.",
    },

    {
      id: "Three",
      question: "Can I rent equipment at LawnLink?",
      answer:
        "Yes, you can rent equipment at LawnLink. We offer a variety of sports equipment for rent, including items for turf games.",
    },

    {
      id: "Four",
      question: "Are there any coaching services available at LawnLink?",
      answer:
        "Yes, LawnLink offers coaching services for various sports. You can contact our customer service for more details and to book a session.",
    },

    {
      id: "Five",
      question: "Can I join leagues or tournaments at LawnLink?",
      answer:
        "Yes, LawnLink hosts various leagues and tournaments. You can join by registering online or contacting our customer service for more information.",
    },
  ];

  return (
    <section className="section best-services">
      <div className="container">
        <div className="section-heading aos" data-aos="fade-up">
          <h2>
            Extra Benefits, Unmatched <span>Service Excellence</span>
          </h2>
          <p className="sub-title">
            Advance your Matches with Lawnlink: Exclusive perks, exceptional
            service.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="best-service-img aos" data-aos="fade-up">
              <img
                src="assets/img/best-service.jpg"
                className="img-fluid"
                alt="Service"
              />
              <div className="service-count-blk">
                {stats.map((stat) => (
                  <div
                    key={stat.title}
                    className={`coach-count ${
                      stat.title === "Courts" ? "coart-count" : ""
                    }`}
                  >
                    <h3>{stat.title}</h3>
                    <h2>
                      <span className="counter-up">{stat.count}</span>+
                    </h2>
                    <h4>{stat.description}</h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ask-questions aos" data-aos="fade-up">
              <h3>Frequently Asked Questions</h3>
              <p>
                Here are some frequently asked questions about badminton at
                Lawnlink:
              </p>
              <div className="faq-info">
                <div className="accordion" id="accordionExample">
                  {faqs.map((faq) => (
                    <div key={faq.id} className="accordion-item">
                      <h2 className="accordion-header" id={`heading${faq.id}`}>
                        <a
                          href="#"
                          className={`accordion-button ${
                            faq.id === "One" ? "" : "collapsed"
                          }`}
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${faq.id}`}
                          aria-expanded={faq.id === "One"}
                          aria-controls={`collapse${faq.id}`}
                        >
                          {faq.question}
                        </a>
                      </h2>
                      <div
                        id={`collapse${faq.id}`}
                        className={`accordion-collapse collapse ${
                          faq.id === "One" ? "show" : ""
                        }`}
                        aria-labelledby={`heading${faq.id}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="accordion-content">
                            <p>{faq.answer}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bestservice;
