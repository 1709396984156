import { useState } from "react";
import "./Dateandtimepicker.css";

const Dateandtimepicker = () => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState([
    { selecttime: "12:00 am", isbooked: false },
    { selecttime: "01:00 am", isbooked: false },
    { selecttime: "02:00 am", isbooked: false },
    { selecttime: "03:00 am", isbooked: false },
    { selecttime: "04:00 am", isbooked: false },
    { selecttime: "05:00 am", isbooked: false },
    { selecttime: "06:00 am", isbooked: false },
    { selecttime: "07:00 am", isbooked: false },
    { selecttime: "08:00 am", isbooked: false },
    { selecttime: "09:00 am", isbooked: false },
    { selecttime: "10:00 am", isbooked: false },
    { selecttime: "11:00 am", isbooked: false },
    { selecttime: "12:00 pm", isbooked: false },
    { selecttime: "01:00 pm", isbooked: false },
    { selecttime: "02:00 pm", isbooked: false },
    { selecttime: "03:00 pm", isbooked: false },
    { selecttime: "04:00 pm", isbooked: false },
    { selecttime: "05:00 pm", isbooked: false },
    { selecttime: "06:00 pm", isbooked: false },
    { selecttime: "07:00 pm", isbooked: false },
    { selecttime: "08:00 pm", isbooked: false },
    { selecttime: "09:00 pm", isbooked: false },
    { selecttime: "10:00 pm", isbooked: false },
    { selecttime: "11:00 pm", isbooked: false },
  ]);

  const handledate = (event) => {
    setDate(event.target.value);
  };


  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };


  const handleDivClick = (index) => {
    setTime((prevState) =>
      prevState.map((time, i) =>
        i === index ? { ...time, isbooked: !time.isbooked } : time
      )
    );
  };

  return (
    <div className="container mt-4">
      <div className="mb-3">
        <label htmlFor="date-picker" className="form-label">
          Select Date
        </label>
        <input
          type="date"
          id="date-picker"
          className="form-control"
          onChange={handledate}
          value={date}
          min={getTodayDate()}
        />
      </div>

      <div className="row">
        {time.map((time, index) => (
          <div
            key={index}
            className={`col-6 col-md-4 col-lg-3 mb-3 ${
              time.isbooked ? "bg-success text-white" : "bg-light"
            }`}
            onClick={() => handleDivClick(index)}
            style={{ cursor: "pointer", padding: "10px", borderRadius: "5px" }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <span>{time.selecttime}</span>
              <span
                className={`badge ${
                  time.isbooked
                    ? "bg-white text-success"
                    : "bg-secondary text-white"
                }`}
              >
                {time.isbooked ? "Booked" : "Available"}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dateandtimepicker;
