import React from "react";
import "./Packageads.css";

const Packageads = () => {
  return (
    <>
      <section className="section convenient-section new-convenient-section">
        <div className="cock-img">
          <div className="cock-img-one">
            <img src="assets/img/icons/cock-01.svg" alt="Icon" />
          </div>
          <div className="cock-img-two">
            <img src="assets/img/icons/cock-02.svg" alt="Icon" />
          </div>
          <div className="cock-circle">
            <img src="assets/img/bg/cock-shape.png" alt="Icon" />
          </div>
        </div>
        <div className="container">
          <div className="convenient-content aos" data-aos="fade-up">
            <h2>Convenient & Flexible Hourly Packages</h2>
            <p>
              Find and book conveniently with our online system that matches
              your schedule and location.
            </p>
          </div>
          <div className="convenient-btns aos" data-aos="fade-up">
            <a
              href="/shopping"
              className="btn btn-primary d-inline-flex align-items-center"
            >
              Book a Turf{" "}
              <span className="lh-1">
                <i className="feather-arrow-right-circle ms-2"></i>
              </span>
            </a>
            <a
              href="/availableoffers"
              className="btn btn-secondary d-inline-flex align-items-center"
            >
              View Offer Availablity{" "}
              <span className="lh-1">
                <i className="feather-arrow-right-circle ms-2"></i>
              </span>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};
export default Packageads;
