import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Home from "./Home/Home";
import Signup from "./Signup/Signup";
import Signin from "./Signin/Signin";
import Turfhome from "./Turfsection/Turfhome";
import Hourlyhome from "./HourlyPackages/Hourlyhome.jsx";
import Availableoffershome from "./Availableoffers/Availableoffershome.jsx";
import Userdashboard from "./Bookings/Userdashboard/Userdashboard.jsx";
// import Demo from "./Demo/Demo.jsx";
import Mybookinghome from "./Bookings/Mybookings/Mybookinghome.jsx";
import Ongoingbookings from "./Bookings/Mybookings/Ongoingbookings.jsx";
import Cancelledbookings from "./Bookings/Mybookings/Cancelledbookings.jsx";
import Completedbookings from "./Bookings/Mybookings/Completedbookings.jsx";
import Turfdetails from "./Abouttheturf/Turfdetails.jsx";
import Invoice from "./Invoice/Invoice.jsx";
import Profileedithome from "./Profileedit/Profileedithome.jsx";
import Underconstruction from "./Noticepage/Underconstruction.jsx";
import Events from "./Pages/Events.jsx";
import FAQ from "./Pages/Faq.jsx";
import Ourteamgallery from "./Pages/Ourteamgallery.jsx";
import Contactus from "./Contactus/Contactus.jsx";
import Bloglist from "./Blog/Bloglist.jsx";
import Blogdetails from "./Blog/Blogdetails.jsx";
import TermsAndConditions from "./Pages/Termsandconditions.jsx";
import PrivacyPolicy from "./Pages/Privacypolicy.jsx";
import Createhostmatch from "./Hostmatches/Createhostmatch";
import Turfselectionform from "./Hostmatches/Turfselectionform";
import Academyform from "./Hostmatches/Academyform";
import Groundform from "./Hostmatches/Groundform";
import Admindevdemo from "./Admin/Admindevdemo.jsx";

import Gallery from "./Pages/Gallery.jsx";
import Bookingnow from "./Bookingorders/Booknow.jsx";
import Orderconfirmation from "./Bookingorders/Orderconfirmation.jsx";
import Payment from "./Bookingorders/Payment.jsx";
import Myprofile from "./Myprofile/Myprofile.jsx";
import Changepassword from "./Profileedit/Changepassword.jsx";
import Othersettings from "./Profileedit/Othersetting.jsx";
import Aboutushome from "./Pages/Aboutus/Aboutushome.jsx";
import Dateandtimepicker from "./Bookingorders/Dateandtimepicker.jsx";

function App() {
  return (
    <div className="App">
      <div id="global-loader">
        <div className="loader-img">
          <img src="assets/img/loader.png" className="img-fluid" alt="Global" />
        </div>
      </div>

      <div className="main-wrapper">
        <Routes>
          <Route path="/" element={<Navigate to={"/home"} />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/home" element={<Home />} />
          <Route path="/Shopping" element={<Turfhome />} />
          <Route path="/hourlypackage" element={<Hourlyhome />} />
          <Route path="/availableoffers" element={<Availableoffershome />} />
          <Route path="/userdashboard" element={<Userdashboard />} />
          <Route path="/mybookings" element={<Mybookinghome />} />
          <Route path="/completedbookings" element={<Completedbookings />} />
          <Route path="/ongoingbookings" element={<Ongoingbookings />} />
          <Route path="/cancelledbookings" element={<Cancelledbookings />} />
          <Route path="/turfdetails" element={<Turfdetails />}></Route>
          <Route path="/invoices" element={<Invoice />}></Route>
          <Route path="/Profileedit" element={<Profileedithome />}></Route>
          <Route path="/events" element={<Events />}></Route>
          <Route path="/faq" element={<FAQ />}></Route>
          <Route path="/aboutushome" element={<Aboutushome />}></Route>
          <Route path="/gallery" element={<Gallery />}></Route>
          <Route path="/bloglist" element={<Bloglist />}></Route>
          <Route path="/contactus" element={<Contactus />}></Route>
          <Route path="/blogdetails" element={<Blogdetails />}></Route>
          <Route path="/datetimepicker" element={<Dateandtimepicker />} />
          <Route
            path="termsandconditions"
            element={<TermsAndConditions />}
          ></Route>
          <Route path="/Privacypolicy" element={<PrivacyPolicy />}></Route>

          {/* <Route path="/demo" element={<Demo />} /> */}
          <Route path="/underconstruction" element={<Underconstruction />} />

          <Route path="/createhostmatch" element={<Createhostmatch />} />
          <Route path="/turfhost" element={<Turfselectionform />} />
          <Route path="/academyhost" element={<Academyform />} />
          <Route path="/groundhost" element={<Groundform />} />
          <Route path="/admin" element={<Admindevdemo />} />
          <Route path="/booknow" element={<Bookingnow />} />
          <Route path="/orderconfirmation" element={<Orderconfirmation />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/myprofile" element={<Myprofile />} />
          <Route path="/changepassword" element={<Changepassword />} />
          <Route path="othersettings" element={<Othersettings />} />
        </Routes>
      </div>

      <div className="progress-wrap active-progress">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            className="progress-path"
          ></path>
        </svg>
      </div>
    </div>
  );
}

export default App;
