import React from "react";
import Header from "../Home/Header";
import "./Invoice.css";
import Footer from "../Footer/Footer";

const invoiceData = [
  {
    id: "#CO14",
    turfName: "Leap Sports Academy",
    courtImage: "assets/img/booking/booking-01.jpg",
    dateTime: "Mon, Jul 12",
    time: "06:00 PM - 08:00 PM",
    payment: "INR150",
    paidOn: "Mon, Jul 12",
    status: "Paid",
    statusClass: "bg-success",
  },
  {
    id: "#CO15",
    turfName: "Wing Sports Academy",
    courtImage: "assets/img/booking/booking-02.jpg",
    dateTime: "Mon, Jul 13",
    time: "07:00 PM - 09:00 PM",
    payment: "INR200",
    paidOn: "Mon, Jul 13",
    status: "Pending",
    statusClass: "bg-info",
  },
  {
    id: "#CO16",
    turfName: "Feather Badminton",
    courtImage: "assets/img/booking/booking-03.jpg",
    dateTime: "Mon, Jul 14",
    time: "01:00 PM - 02:00 PM",
    payment: "INR150",
    paidOn: "Mon, Jul 14",
    status: "Failed",
    statusClass: "bg-danger",
  },
  {
    id: "#CO17",
    turfName: "Bwing Sports Academy",
    courtImage: "assets/img/booking/booking-04.jpg",
    dateTime: "Mon, Jul 15",
    time: "01:00 PM - 04:00 PM",
    payment: "INR90",
    paidOn: "Mon, Jul 15",
    status: "Paid",
    statusClass: "bg-success",
  },
  {
    id: "#CO18",
    turfName: "Bwing Sports Academy",
    courtImage: "assets/img/booking/booking-05.jpg",
    dateTime: "Mon, Jul 16",
    time: "03:00 PM - 05:00 PM",
    payment: "INR180",
    paidOn: "Mon, Jul 16",
    status: "Paid",
    statusClass: "bg-success",
  },
];

const Invoice = () => {
  return (
    <>
      {" "}
      <Header></Header>
      <div className="content new-invoice-content">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="court-tab-content">
                <div className="card card-tableset">
                  <div className="card-body">
                    <div className="coache-head-blk">
                      <div className="row align-items-center">
                        <div className="col-md-5">
                          <div className="court-table-head">
                            <h4>Invoices</h4>
                            <p>
                              Access recent invoices related to Turf bookings
                            </p>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="table-search-top invoice-search-top">
                            <div id="tablefilter"></div>
                            <div className="request-coach-list select-filter">
                              <div className="sortby-filter-group court-sortby">
                                <div className="sortbyset m-0">
                                  <div className="sorting-select">
                                    <select className="form-control select">
                                      <option>All Invoices</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive table-datatble">
                      <table className="table datatable">
                        <thead className="thead-light">
                          <tr>
                            <th>ID</th>
                            <th>Turf Name</th>
                            <th>Date & Time </th>
                            <th>Payment</th>
                            <th>Paid On</th>
                            <th>Status</th>
                            <th>Download</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceData.map((invoice) => (
                            <tr key={invoice.id}>
                              <td>
                                <a href="#" className="text-primary">
                                  {invoice.id}
                                </a>
                              </td>
                              <td>
                                <h2 className="table-avatar">
                                  <a
                                    href="#"
                                    className="avatar avatar-sm flex-shrink-0"
                                  >
                                    <img
                                      className="avatar-img"
                                      src={invoice.courtImage}
                                      alt="Court Image"
                                    />
                                  </a>
                                  <span className="table-head-name flex-grow-1">
                                    <a href="#">{invoice.turfName}</a>
                                    <span>Court 1</span>
                                  </span>
                                </h2>
                              </td>
                              <td className="table-date-time">
                                <h4>
                                  {invoice.dateTime}
                                  <span>{invoice.time}</span>
                                </h4>
                              </td>
                              <td>
                                <span className="pay-dark fs-16">
                                  {invoice.payment}
                                </span>
                              </td>
                              <td>{invoice.paidOn}</td>
                              <td>
                                <span
                                  className={`badge INR{invoice.statusClass}`}
                                >
                                  <i className="feather-check-square me-1"></i>
                                  {invoice.status}
                                </span>
                              </td>
                              <td className="text-pink view-detail-pink">
                                <a href="#">
                                  <i className="feather-download"></i>Download
                                </a>
                              </td>
                              <td className="text-end">
                                <div className="dropdown dropdown-action table-drop-action">
                                  <a
                                    href="#"
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis-h"></i>
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <a className="dropdown-item" href="#;">
                                      <i className="feather-briefcase"></i>
                                      Refund
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      <i className="feather-trash"></i>Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="tab-footer">
                  <div className="row">
                    <div className="col-md-6">
                      <div id="tablelength"></div>
                    </div>
                    <div className="col-md-6">
                      <div id="tablepage"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Invoice;
