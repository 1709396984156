import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Turfdetailscoursel.css";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 2,
};

const Gallery = () => (
  <div className="content venue-coach-details">
    <div className="container">
      <div className="gallery">
        <Slider {...settings}>
          <div>
            <img
              src="assets/img/gallery/gallery1/gallery-01.png"
              alt="Gallery Item"
            />
          </div>
          <div>
            <img
              src="assets/img/gallery/gallery1/gallery-02.png"
              alt="Gallery Item"
            />
          </div>
          <div>
            <img
              src="assets/img/gallery/gallery1/gallery-01.png"
              alt="Gallery Item"
            />
          </div>
          <div>
            <img
              src="assets/img/gallery/gallery1/gallery-02.png"
              alt="Gallery Item"
            />
          </div>
          <div>
            <img
              src="assets/img/gallery/gallery1/gallery-01.png"
              alt="Gallery Item"
            />
          </div>
          <div>
            <img
              src="assets/img/gallery/gallery1/gallery-02.png"
              alt="Gallery Item"
            />
          </div>
        </Slider>
      </div>
    </div>
  </div>
);

export default Gallery;
