import { useEffect, useState } from "react";
import "./Admindevdemo.css";

function Admin() {
  const [user, setUser] = useState([]);

  const getUser = () => {
    fetch("https://api.dremerz.net/api/lawnlinksaveuser")
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        if (json.length > 0) {
          setUser(json);
        }
      });
  };

  const handleLogout = () => {
    sessionStorage.removeItem("user");
    window.location.href = "/signin";
  };

  const downloadExcel = () => {
    const rows = [["S.no", "UserName", "Email", "Create_On", "Custom_User_Id"]];
    user.forEach((item, index) => {
      rows.push([
        index + 1,
        item.user_name,
        item.email,
        item.create_on,
        item.custom_user_id,
      ]);
    });

    const csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "user_details.csv");
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="admin">
      <h2>Admin Management</h2>
      <table className="user-table">
        <thead className="userlist-header">
          <tr>
            <th>S.No</th>
            <th>Username</th>
            <th>Email</th>
            <th>created_on</th>
            <th>Custom_user_id</th>
          </tr>
        </thead>
        <tbody>
          {user.map((item, i) => {
            return (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{item.user_name}</td>
                <td>{item.email}</td>
                <td>{item.create_on}</td>
                <td>{item.custom_user_id}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
      <button className="download-button" onClick={downloadExcel}>
        Download Excel
      </button>
    </div>
  );
}

export default Admin;
