import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";
import "./Createhostmatch.css";

const CreateHostMatch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectionChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (value === "turf-selection") {
      navigate("/turfhost");
    } else if (value === "academy") {
      navigate("/academyhost");
    } else if (value === "ground") {
      navigate("/groundhost");
    }
  };

  return (
    <div className="content">
      <div className="container">
        <Form>
          <Form.Group controlId="hostMatchDropdown">
            <Form.Label>Select Host Match Type</Form.Label>
            <div className="custom-dropdown-container">
              <select
                value={selectedValue}
                onChange={handleSelectionChange}
                className="form-select"
                aria-label="Default select example"
              >
                <option value="">Select...</option>
                <option value="turf-selection">Turf Selection</option>
                <option value="academy">Academy</option>
              </select>
            </div>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default CreateHostMatch;
