import React from "react";

const bookings = [
  {
    imgSrc: "assets/img/booking/booking-02.jpg",
    academyName: "Leap Sports Academy",
    court: "Court 1",
    guests: 4,
    duration: "2 Hrs",
    date: "Mon, Jul 11",
    time: "06:00 PM - 08:00 PM",
    price: "INR_400",
  },
  {
    imgSrc: "assets/img/booking/booking-03.jpg",
    academyName: "Wing Sports Academy",
    court: "Court 2",
    guests: 3,
    duration: "1 Hr",
    date: "Tue, Jul 12",
    time: "07:00 PM - 08:00 PM",
    price: "INR_240",
  },
  {
    imgSrc: "assets/img/booking/booking-04.jpg",
    academyName: "Feather Badminton",
    court: "Court 1",
    guests: 1,
    duration: "4 Hrs",
    date: "Wed, Jul 13",
    time: "10:00 PM - 11:00 PM",
    price: "INR_320",
  },
  {
    imgSrc: "assets/img/booking/booking-05.jpg",
    academyName: "Bwing Sports Academy",
    court: "Court 3",
    guests: 5,
    duration: "6 Hrs",
    date: "Thu, Jul 14",
    time: "09:00 AM - 10:00 AM",
    price: "INR_710",
  },
  {
    imgSrc: "assets/img/booking/booking-06.jpg",
    academyName: "Marsh Academy",
    court: "Court 2",
    guests: 3,
    duration: "2 Hrs",
    date: "Fri, Jul 15",
    time: "11:00 AM - 12:00 PM",
    price: "INR_820",
  },
];

const Mybookings = () => {
  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-12 d-flex">
              <div className="card dashboard-card flex-fill">
                <div className="card-header card-header-info">
                  <div className="card-header-inner">
                    <h4>My Bookings</h4>
                    <p>Turf Reservations Made Easy</p>
                  </div>
                  <div className="card-header-btns">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <button
                          className="nav-link active"
                          id="nav-Court-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-Court"
                          type="button"
                          role="tab"
                          aria-controls="nav-Court"
                          aria-selected="true"
                        >
                          Court
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="nav-Court"
                    role="tabpanel"
                    aria-labelledby="nav-Court-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive dashboard-table-responsive">
                      <table className="table dashboard-card-table">
                        <tbody>
                          {bookings.map((booking, index) => (
                            <tr key={index}>
                              <td>
                                <div className="academy-info">
                                  <a href="#" className="academy-img">
                                    <img src={booking.imgSrc} alt="Booking" />
                                  </a>
                                  <div className="academy-content">
                                    <h6>
                                      <a
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#upcoming-court"
                                      >
                                        {booking.academyName}
                                      </a>
                                    </h6>
                                    <span>{booking.court}</span>
                                    <ul>
                                      <li>Guests : {booking.guests}</li>
                                      <li>{booking.duration}</li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <h6>Date & Time</h6>
                                <p>{booking.date}</p>
                                <p>{booking.time}</p>
                              </td>
                              <td>
                                <h4>{booking.price}</h4>
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <a
                                    href="#"
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis"></i>
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <a className="dropdown-item" href="#">
                                      <i className="feather-x-circle"></i>{" "}
                                      Cancel
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-Coaching"
                    role="tabpanel"
                    aria-labelledby="nav-Coaching-tab"
                    tabIndex="0"
                  ></div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-12 d-flex flex-column">
              <div className="card payment-card">
                <div className="payment-info">
                  <div className="payment-content">
                    <p>Host Your Events</p>
                    <h2>Starts From INR 4,500</h2>
                  </div>
                  <div className="payment-btn">
                    <a
                      href="/turfhost"
                      className="btn"
                      data-bs-toggle="modal"
                      data-bs-target="#add-payment"
                    >
                      Host A Match
                    </a>
                  </div>
                </div>
              </div>
              <div className="card dashboard-card upcoming-card">
                <div className="card-header card-header-info">
                  <div className="card-header-inner">
                    <h4>Upcoming Ads</h4>
                    <p>Manage all your upcoming Ads.</p>
                  </div>
                  <div className="card-header-btns">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <button
                          className="nav-link active"
                          id="nav-Appointment-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-Appointment"
                          type="button"
                          role="tab"
                          aria-controls="nav-Appointment"
                          aria-selected="true"
                        >
                          Court
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="nav-Appointment"
                    role="tabpanel"
                    aria-labelledby="nav-Appointment-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive dashboard-table-responsive">
                      <table className="table dashboard-card-table">
                        <tbody>
                          <tr>
                            <td>
                              <div className="academy-info academy-info">
                                <a href="#" className="academy-img">
                                  <img
                                    src="assets/img/booking/booking-02.jpg"
                                    alt="Booking"
                                  />
                                </a>
                                <div className="academy-content">
                                  <h6>
                                    <a
                                      data-bs-toggle="modal"
                                      data-bs-target="#upcoming-court"
                                    >
                                      Leap Sports Academy
                                    </a>
                                  </h6>
                                  <ul>
                                    <li>Court 1</li>
                                    <li>
                                      <i className="feather-clock"></i> 06:00 PM
                                      to 08:00 PM
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="dropdown dropdown-action">
                                <a
                                  href="#"
                                  className="action-icon dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fas fa-ellipsis"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a className="dropdown-item" href="#">
                                    <i className="feather-x-circle"></i> Visit
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-AppointmentCoaching"
                    role="tabpanel"
                    aria-labelledby="nav-AppointmentCoaching-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive dashboard-table-responsive">
                      <table className="table dashboard-card-table">
                        <tbody>
                          <tr>
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">
                                  <i className="feather-x-circle"></i> Visit
                                </a>
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-AppointmentPodcast"
                    role="tabpanel"
                    aria-labelledby="nav-AppointmentPodcast-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive dashboard-table-responsive">
                      <table className="table dashboard-card-table">
                        <tbody>
                          <tr>
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">
                                  <i className="feather-x-circle"></i> Visit
                                </a>
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-AppointmentLive"
                    role="tabpanel"
                    aria-labelledby="nav-AppointmentLive-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive dashboard-table-responsive">
                      <table className="table dashboard-card-table">
                        <tbody>
                          <tr>
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">
                                  <i className="feather-x-circle"></i> Visit
                                </a>
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-AppointmentFuture"
                    role="tabpanel"
                    aria-labelledby="nav-AppointmentFuture-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive dashboard-table-responsive">
                      <table className="table dashboard-card-table">
                        <tbody>
                          <tr>
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">
                                  <i className="feather-x-circle"></i> Visit
                                </a>
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card dashboard-card current-card">
                <div className="card-header card-header-info">
                  <div className="card-header-inner">
                    <h4>Current Ads</h4>
                    <p>Manage all your current Ads.</p>
                  </div>
                  <div className="card-header-btns">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <button
                          className="nav-link active"
                          id="nav-Current-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-Current"
                          type="button"
                          role="tab"
                          aria-controls="nav-Current"
                          aria-selected="true"
                        >
                          Court
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="nav-Current"
                    role="tabpanel"
                    aria-labelledby="nav-Current-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive dashboard-table-responsive">
                      <table className="table dashboard-card-table">
                        <tbody>
                          <tr>
                            <td>
                              <div className="academy-info academy-info">
                                <a href="#" className="academy-img">
                                  <img
                                    src="assets/img/booking/booking-02.jpg"
                                    alt="Booking"
                                  />
                                </a>
                                <div className="academy-content">
                                  <h6>
                                    <a
                                      data-bs-toggle="modal"
                                      data-bs-target="#current-court"
                                    >
                                      Leap Sports Academy
                                    </a>
                                  </h6>
                                  <ul>
                                    <li>Court 1</li>
                                    <li>
                                      <i className="feather-clock"></i> 06:00 PM
                                      to 08:00 PM
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="dropdown dropdown-action">
                                <a
                                  href="#"
                                  className="action-icon dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fas fa-ellipsis"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a className="dropdown-item" href="#">
                                    <i className="feather-x-circle"></i> Visit
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-CurrentCoaching"
                    role="tabpanel"
                    aria-labelledby="nav-CurrentCoaching-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive dashboard-table-responsive">
                      <table className="table dashboard-card-table">
                        <tbody>
                          <tr>
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">
                                  <i className="feather-x-circle"></i> Visit
                                </a>
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-CurrentPodcast"
                    role="tabpanel"
                    aria-labelledby="nav-CurrentPodcast-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive dashboard-table-responsive">
                      <table className="table dashboard-card-table">
                        <tbody>
                          <tr>
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">
                                  <i className="feather-x-circle"></i> Visit
                                </a>
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-CurrentLive"
                    role="tabpanel"
                    aria-labelledby="nav-CurrentLive-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive dashboard-table-responsive">
                      <table className="table dashboard-card-table">
                        <tbody>
                          <tr>
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">
                                  <i className="feather-x-circle"></i> Visit
                                </a>
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-CurrentFuture"
                    role="tabpanel"
                    aria-labelledby="nav-CurrentFuture-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive dashboard-table-responsive">
                      <table className="table dashboard-card-table">
                        <tbody>
                          <tr>
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">
                                  <i className="feather-x-circle"></i> Visit
                                </a>
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card dashboard-card booking-card">
                <div className="card-header card-header-danger">
                  <div className="card-header-inner">
                    <h4>upcoming ads 2</h4>
                    <p></p>
                  </div>
                  <div className="card-header-btns">
                    <nav>
                      <div className="nav nav-tabs" role="tablist">
                        <button
                          className="nav-link active"
                          id="nav-Cancelled-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-Cancelled"
                          type="button"
                          role="tab"
                          aria-controls="nav-Cancelled"
                          aria-selected="true"
                        >
                          Court
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="nav-Cancelled"
                    role="tabpanel"
                    aria-labelledby="nav-Cancelled-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive dashboard-table-responsive">
                      <table className="table dashboard-card-table">
                        <tbody>
                          <tr>
                            <td>
                              <div className="academy-info academy-info">
                                <a href="#" className="academy-img">
                                  <img
                                    src="assets/img/booking/booking-02.jpg"
                                    alt="Booking"
                                  />
                                </a>
                                <div className="academy-content">
                                  <h6>
                                    <a
                                      data-bs-toggle="modal"
                                      data-bs-target="#cancelled-court"
                                    >
                                      Leap Sports Academy
                                    </a>
                                  </h6>
                                  <ul>
                                    <li>Court 1</li>
                                    <li>
                                      <i className="feather-clock"></i> 06:00 PM
                                      to 08:00 PM
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="dropdown dropdown-action">
                                <a
                                  href="#"
                                  className="action-icon dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fas fa-ellipsis"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a className="dropdown-item" href="#">
                                    <i className="feather-x-circle"></i> Visit
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-CancelledCoaching"
                    role="tabpanel"
                    aria-labelledby="nav-CancelledCoaching-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive dashboard-table-responsive">
                      <table className="table dashboard-card-table">
                        <tbody>
                          <tr>
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">
                                  <i className="feather-x-circle"></i> Visit
                                </a>
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-CancelledPodcast"
                    role="tabpanel"
                    aria-labelledby="nav-CancelledPodcast-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive dashboard-table-responsive">
                      <table className="table dashboard-card-table">
                        <tbody>
                          <tr>
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">
                                  <i className="feather-x-circle"></i> Visit
                                </a>
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-CancelledLive"
                    role="tabpanel"
                    aria-labelledby="nav-CancelledLive-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive dashboard-table-responsive">
                      <table className="table dashboard-card-table">
                        <tbody>
                          <tr>
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">
                                  <i className="feather-x-circle"></i> Visit
                                </a>
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-CancelledFuture"
                    role="tabpanel"
                    aria-labelledby="nav-CancelledFuture-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive dashboard-table-responsive">
                      <table className="table dashboard-card-table">
                        <tbody>
                          <tr>
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">
                                  <i className="feather-x-circle"></i> Visit
                                </a>
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mybookings;
