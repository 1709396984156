import React, { useState } from "react";

const Othersetting = () => {
  const handleDeactivate = () => {
    console.log("Account deactivated");
  };

  return (
    <div className="content court-bg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="profile-detail-group">
              <div className="section-heading aos" data-aos="fade-up">
                <h2>
                  <span>Other Settings</span>
                </h2>
              </div>

              <div className="card">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="deactivate-account-blk">
                      <div className="deactivate-detail">
                        <h4>Deactivate Account</h4>
                        <p>Click the button below to deactivate the account</p>
                      </div>
                      <button
                        type="button"
                        className="btn deactive-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#deactive"
                      >
                        <i className="feather-zap-off"></i>Deactivate Account
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="deactive"
        tabIndex="-1"
        aria-labelledby="deactiveLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deactiveLabel">
                Deactivate Account
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Are you sure you want to deactivate your account?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeactivate}
                data-bs-dismiss="modal"
              >
                Deactivate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Othersetting;
