import React from "react";

const Groupcoaching = () => {
  const features = [
    {
      id: 4,
      imgSrc: "assets/img/icons/coache-icon-04.svg",
      title: "Innovative Lessons",
      description:
        "Discover a new standard in turf booking and take your play to the next level.",
    },
    {
      id: 5,
      imgSrc: "assets/img/icons/coache-icon-05.svg",
      title: "Turf Community",
      description:
        "Boost your game with top-quality turfs and a vibrant community. Book now and experience exceptional facilities that elevate your playing experience to new heights.",
    },
    {
      id: 6,
      imgSrc: "assets/img/icons/coache-icon-06.svg",
      title: "Turf Rental",
      description:
        "List your court with us to attract more bookings and grow your business effortlessly. Join our platform and showcase your turf to a wider audience today!",
    },
  ];

  return (
    <section className="section group-coaching">
      <div className="container">
        <div className="section-heading aos" data-aos="fade-up">
          <h2>
            Our <span>Features</span>
          </h2>
          <p className="sub-title">
            Join us to access top-tier facilities, connect with Premium Turfs,
            and unlock new levels of performance.
          </p>
        </div>
        <div className="row justify-content-center">
          {features.map(({ id, imgSrc, title, description }) => (
            <div key={id} className="col-lg-4 col-md-6 d-flex">
              <div
                className="work-grid coaching-grid w-100 aos"
                data-aos="fade-up"
              >
                <div className="work-icon">
                  <div className="work-icon-inner">
                    <img src={imgSrc} alt={title} />
                  </div>
                </div>
                <div className="work-content">
                  <h3>{title}</h3>
                  <p>{description}</p>
                  <a href="events">Learn More</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Groupcoaching;
