import { useState } from "react";
import "./Hero-section.css";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  const handlesearch = (event) => {
    event.preventDefault();
    debugger;
    const category = document.getElementsByName("category")[0].value;
    const location = document.getElementsByName("location")[0].value;
    sessionStorage.setItem("location", location);
    sessionStorage.setItem("category", category);
    if (category == "turfs") {
      navigate("/shopping");
    } else {
      navigate("/events");
    }
  };

  return (
    <section className="hero-section">
      <div className="banner-cock-one">
        <img src="assets/img/icons/banner-cock1.svg" alt="Banner" />
      </div>
      <div className="banner-shapes">
        <div className="banner-dot-one">
          <span></span>
        </div>
        <div className="banner-cock-two">
          <img src="assets/img/icons/banner-cock2.svg" alt="Banner" />
          <span></span>
        </div>
        <div className="banner-dot-two">
          <span></span>
        </div>
      </div>
      <div className="container">
        <div className="home-banner">
          <div className="row align-items-center w-100">
            <div className="col-lg-7 col-md-10 mx-auto">
              <div className="section-search aos" data-aos="fade-up">
                <h4>
                  Browse our selection of pristine turfs for your next game
                </h4>
                <h1>
                  Choose Your <span>Turf</span> and Start Your Match
                </h1>
                <p className="sub-info">
                  Plan your matches with confidence on our top-tier turf
                  facilities with Experience unmatched quality and service when
                  you book with us
                </p>
                <div className="search-box">
                  <form onSubmit={handlesearch}>
                    <div className="search-input line">
                      <div className="form-group mb-0">
                        <label>Search for</label>
                        <select
                          className="select"
                          // value={category}
                          name="category"
                          id="category"
                        >
                          <option value="">Choose Category</option>
                          <option value="turfs">Turfs</option>
                          <option value="events">Events</option>
                        </select>
                      </div>
                    </div>
                    <div className="search-input">
                      <div className="form-group mb-0">
                        <label>Where </label>
                        <select
                          // value={location}
                          className="select"
                          id="location"
                          name="location"
                        >
                          <option value="">Choose Location</option>
                          <option value="chennai">Chennai</option>
                          <option value="madurai">Madurai</option>
                          <option value="thanjavur">Thanjavur</option>
                          <option value="salem">Salem</option>
                          <option value="coimbatore">Coimbatore</option>
                        </select>
                      </div>
                    </div>
                    <div className="search-btn">
                      <button className="btn" type="submit">
                        <i className="feather-search"></i>
                        <span className="search-text">Search</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="banner-imgs text-center aos" data-aos="fade-up">
                <img
                  className="img-fluid hero-section-img01"
                  src="assets/Homeimg/Home-hero-01.jpeg"
                  alt="Banner"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
