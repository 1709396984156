import React, { useState } from "react";
import PropTypes from "prop-types";
import Header from "../Home/Header";
import "./Events.css";
import Footer from "../Footer/Footer";

// Consolidated servicesData
const servicesData = {
  AllEvents: [
    {
      id: 1,
      imgSrc: "assets/img/services/service-05.jpg",
      title: "Turf events",
      description:
        "Book your Team to next big match at our premium courts! Check availability. Reserve now to secure your spot!",
      link: "#",
      matchtype: "tournament",
      entryfees: "500",
      dateandtime: "12.12.2024",
      rules: "below 18",
      turfname: "Greengrass",
      city: "chennai",
      contactNo: "9998887770",
    },
    {
      id: 1,
      imgSrc: "assets/img/services/service-05.jpg",
      title: "Academy events",
      description:
        "Book your Team to next big match at our premium courts! Check availability. Reserve now to secure your spot!",
      link: "#",
      matchtype: "tournament",
      entryfees: "500",
      dateandtime: "12.12.2024",
      rules: "below 18",
      turfname: "Greengrass",
      city: "chennai",
      contactNo: "9998887770",
    },
    {
      id: 1,
      imgSrc: "assets/img/services/service-05.jpg",
      title: "Turf events",
      description:
        "Book your Team to next big match at our premium courts! Check availability. Reserve now to secure your spot!",
      link: "#",
      matchtype: "tournament",
      entryfees: "500",
      dateandtime: "12.12.2024",
      rules: "below 18",
      turfname: "Greengrass",
      city: "chennai",
      contactNo: "9998887770",
    },
    {
      id: 1,
      imgSrc: "assets/img/services/service-05.jpg",
      title: "Academy events",
      description:
        "Book your Team to next big match at our premium courts! Check availability. Reserve now to secure your spot!",
      link: "#",
      matchtype: "tournament",
      entryfees: "500",
      dateandtime: "12.12.2024",
      rules: "below 18",
      turfname: "Greengrass",
      city: "chennai",
      contactNo: "9998887770",
    },
    {
      id: 1,
      imgSrc: "assets/img/services/service-05.jpg",
      title: "Turf events",
      description:
        "Book your Team to next big match at our premium courts! Check availability. Reserve now to secure your spot!",
      link: "#",
      matchtype: "tournament",
      entryfees: "500",
      dateandtime: "12.12.2024",
      rules: "below 18",
      turfname: "Greengrass",
      city: "chennai",
      contactNo: "9998887770",
    },
    {
      id: 1,
      imgSrc: "assets/img/services/service-05.jpg",
      title: "Academy events",
      description:
        "Book your Team to next big match at our premium courts! Check availability. Reserve now to secure your spot!",
      link: "#",
      matchtype: "tournament",
      entryfees: "500",
      dateandtime: "12.12.2024",
      rules: "below 18",
      turfname: "Greengrass",
      city: "chennai",
      contactNo: "9998887770",
    },
    {
      id: 1,
      imgSrc: "assets/img/services/service-05.jpg",
      title: "Turf events",
      description:
        "Book your Team to next big match at our premium courts! Check availability. Reserve now to secure your spot!",
      link: "#",
      matchtype: "tournament",
      entryfees: "500",
      dateandtime: "12.12.2024",
      rules: "below 18",
      turfname: "Greengrass",
      city: "chennai",
      contactNo: "9998887770",
    },
    {
      id: 1,
      imgSrc: "assets/img/services/service-05.jpg",
      title: "Academy events",
      description:
        "Book your Team to next big match at our premium courts! Check availability. Reserve now to secure your spot!",
      link: "#",
      matchtype: "tournament",
      entryfees: "500",
      dateandtime: "12.12.2024",
      rules: "below 18",
      turfname: "Greengrass",
      city: "chennai",
      contactNo: "9998887770",
    },
    {
      id: 1,
      imgSrc: "assets/img/services/service-05.jpg",
      title: "Turf events",
      description:
        "Book your Team to next big match at our premium courts! Check availability. Reserve now to secure your spot!",
      link: "#",
      matchtype: "tournament",
      entryfees: "500",
      dateandtime: "12.12.2024",
      rules: "below 18",
      turfname: "Greengrass",
      city: "chennai",
      contactNo: "9998887770",
    },
    {
      id: 1,
      imgSrc: "assets/img/services/service-05.jpg",
      title: "Academy events",
      description:
        "Book your Team to next big match at our premium courts! Check availability. Reserve now to secure your spot!",
      link: "#",
      matchtype: "tournament",
      entryfees: "500",
      dateandtime: "12.12.2024",
      rules: "below 18",
      turfname: "Greengrass",
      city: "chennai",
      contactNo: "9998887770",
    },
    {
      id: 1,
      imgSrc: "assets/img/services/service-05.jpg",
      title: "Turf events",
      description:
        "Book your Team to next big match at our premium courts! Check availability. Reserve now to secure your spot!",
      link: "#",
      matchtype: "tournament",
      entryfees: "500",
      dateandtime: "12.12.2024",
      rules: "below 18",
      turfname: "Greengrass",
      city: "chennai",
      contactNo: "9998887770",
    },
    {
      id: 1,
      imgSrc: "assets/img/services/service-05.jpg",
      title: "Academy events",
      description:
        "Book your Team to next big match at our premium courts! Check availability. Reserve now to secure your spot!",
      link: "#",
      matchtype: "tournament",
      entryfees: "500",
      dateandtime: "12.12.2024",
      rules: "below 18",
      turfname: "Greengrass",
      city: "chennai",
      contactNo: "9998887770",
    },
    // Add more data as needed
  ],
  Turfs: [
    {
      id: 1,
      imgSrc: "assets/img/services/service-05.jpg",
      title: "Sports Performance Training",
      description:
        "LawnLink offers tailored sports performance training programs designed to enhance your athletic performance in badminton.",
      link: "#",
      matchtype: "",
      entryfees: "",
      dateandtime: "",
      rules: "",
      turfname: "",
      city: "",
      contactNo: "",
    },
    // Add more data as needed
  ],
  Academy: [
    {
      id: 1,
      imgSrc: "assets/img/services/service-05.jpg",
      title: "Elite Badminton",
      description:
        "Our team of experienced and qualified coaches is dedicated to helping you achieve your goals.",
      link: "#",
      matchtype: "",
      entryfees: "",
      dateandtime: "",
      rules: "",
      turfname: "",
      city: "",
      contactNo: "",
    },
    // Add more data as needed
  ],
};

// ServiceListing component
const ServiceListing = ({
  imgSrc,
  title,
  description,
  link,
  matchtype,
  entryfees,
  dateandtime,
  rules,
  turfname,
  city,
  contactNo,
}) => (
  <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
    <div className="card border-0 shadow-sm">
      <a href={link} className="d-block">
        <img src={imgSrc} className="card-img-top" alt={title} />
      </a>
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{description}</p>
        {matchtype && (
          <p className="card-text">
            <strong>Match Type:</strong> {matchtype}
          </p>
        )}
        {entryfees && (
          <p className="card-text">
            <strong>Entry Fees:</strong> {entryfees}
          </p>
        )}
        {dateandtime && (
          <p className="card-text">
            <strong>Date and Time:</strong> {dateandtime}
          </p>
        )}
        {rules && (
          <p className="card-text">
            <strong>Rules:</strong> {rules}
          </p>
        )}
        {turfname && (
          <p className="card-text">
            <strong>Turf Name:</strong> {turfname}
          </p>
        )}
        {city && (
          <p className="card-text">
            <strong>City:</strong> {city}
          </p>
        )}
        {contactNo && (
          <p className="card-text">
            <strong>Contact No:</strong> {contactNo}
          </p>
        )}
      </div>
    </div>
  </div>
);

ServiceListing.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  matchtype: PropTypes.string,
  entryfees: PropTypes.string,
  dateandtime: PropTypes.string,
  rules: PropTypes.string,
  turfname: PropTypes.string,
  city: PropTypes.string,
  contactNo: PropTypes.string,
};

// Services component
const Services = () => {
  const [activeTab, setActiveTab] = useState("AllEvents");

  return (
    <>
      <Header />
      <div className="content new-eventshome">
        <div className="container">
          <section className="services">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              {Object.keys(servicesData).map((tab) => (
                <li className="nav-item" role="presentation" key={tab}>
                  <button
                    className={`nav-link ${activeTab === tab ? "active" : ""}`}
                    id={`pills-${tab}-tab`}
                    data-bs-toggle="pill"
                    data-bs-target={`#pills-${tab}`}
                    type="button"
                    role="tab"
                    aria-controls={`pills-${tab}`}
                    aria-selected={activeTab === tab}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </button>
                </li>
              ))}
            </ul>
            <div className="tab-content" id="pills-tabContent">
              {Object.entries(servicesData).map(([tab, services]) => (
                <div
                  className={`tab-pane fade ${
                    activeTab === tab ? "show active" : ""
                  }`}
                  id={`pills-${tab}`}
                  role="tabpanel"
                  aria-labelledby={`pills-${tab}-tab`}
                  key={tab}
                >
                  <div className="row">
                    {services.map((service) => (
                      <ServiceListing key={service.id} {...service} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
