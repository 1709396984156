const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer-join aos" data-aos="fade-up">
            <h2>We Welcome Your Passion And Expertise</h2>
            <p className="sub-title">
              Join our empowering sports community today and grow with us.
            </p>
            <a href="/signup" className="btn btn-primary">
              <i className="feather-user-plus"></i> Join With Us
            </a>
          </div>

          <div className="footer-top">
            <div className="row">
              <div className="col-lg-2 col-md-6"></div>

              <div className="col-lg-2 col-md-6">
                <div className="footer-widget footer-menu">
                  <h4 className="footer-title">Contact us</h4>
                  <div className="footer-address-blk">
                    <div className="footer-call">
                      {/* <span>Contact</span> */}
                      <p>+91 9488739021</p>
                      <p>+91 7904922816</p>
                    </div>
                    <div className="footer-call">
                      <span>Need Live Suppot</span>
                      <p>
                        <a
                          href=""
                          className="__cf_email__"
                          data-cfemail="dbbfa9bebab6a8abb4a9afa89bbea3bab6abb7bef5b8b4b6"
                        >
                          lawnlink2024@gmail.com
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="social-icon">
                    <ul>
                      {/* <li>
                        <a href="#" className="facebook">
                          <i className="fab fa-facebook-f"></i>{" "}
                        </a>
                      </li> */}
                      <li>
                        <a
                          href="https://x.com/lawn__link?t=8cWHA7b2BKCQz_6QuL6SSQ&s=09"
                          className="twitter"
                        >
                          <i className="fab fa-twitter"></i>{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/lawn_link?igsh=ZnQzYXVxemRiNTU2
"
                          className="instagram"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/in/lawn-link-2a9119320?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                          className="linked-in"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="footer-widget footer-menu">
                  <h4 className="footer-title">Quick Links</h4>
                  <ul>
                    <li>
                      <a href="/aboutushome">About us</a>
                    </li>
                    {/* <li>
                      <a href="/service">Services</a>
                    </li> */}
                    <li>
                      <a href="events">Events</a>
                    </li>
                    {/* <li>
                      <a href="bloglist">Blogs</a>
                    </li> */}
                    {/* <li>
                      <a href="/contactus">Contact us</a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="footer-widget footer-menu">
                  <h4 className="footer-title">Support</h4>
                  <ul>
                    {/* <li>
                      <a href="/contactus">Contact Us</a>
                    </li> */}
                    <li>
                      <a href="/faq">Faq</a>
                    </li>
                    <li>
                      <a href="/privacypolicy">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="termsandconditions">Terms & Conditions</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="footer-widget footer-menu">
                  <h4 className="footer-title">Explore More</h4>
                  <ul>
                    <li>
                      <a href="shopping">Turf Booking</a>
                    </li>
                    {/* <li>
                      <a href="#">Facilities</a>
                    </li> */}
                    <li>
                      <a href="contactus">Become a Partner</a>
                    </li>
                    {/* <li>
                      <a href="#">Add a Facility</a>
                    </li> */}
                    <li>
                      <a href="myprofile">My Account</a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <div className="col-lg-2 col-md-6">
                <div className="footer-widget footer-menu">
                  <h4 className="footer-title">Our Locations</h4>
                  <ul>
                    <li>
                      <a href="#">Chennai</a>
                    </li>
                    <li>
                      <a href="#">Bangalore</a>
                    </li>
                    <li>
                      <a href="#">Delhi</a>
                    </li>
                    <li>
                      <a href="#">Mumbai</a>
                    </li>
                  </ul>
                </div>
              </div> */}
              {/* <div className="col-lg-2 col-md-6">
                <div className="footer-widget footer-menu">
                  <h4 className="footer-title">Download</h4>
                  <ul>
                    <li>
                      <a href="#">
                        <img
                          src="assets/img/icons/icon-apple.svg"
                          alt="Apple"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src="assets/img/icons/google-icon.svg"
                          alt="Google"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="copyright">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="copyright-text">
                    <p className="mb-0">
                      &copy; 2023 Lawnlink - All rights reserved.
                    </p>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="dropdown-blk">
                    <ul className="navbar-nav selection-list">
                      <li className="nav-item dropdown">
                        <div className="lang-select">
                          <span className="select-icon">
                            <i className="feather-globe"></i>
                          </span>
                          <select className="select">
                            <option>English (US)</option>
                            <option>UK</option>
                            <option>Japan</option>
                          </select>
                        </div>
                      </li>
                      <li className="nav-item dropdown">
                        <div className="lang-select">
                          <span className="select-icon"></span>
                          <select className="select">
                            <option>$ USD</option>
                            <option>$ Euro</option>
                          </select>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
