import React from "react";

const AccordionSection = ({ id, title, content, isOpen = false }) => (
  <div className="accordion-item mb-4" id={id}>
    <h4 className="accordion-header" id={`panelsStayOpen-${id}`}>
      <button
        className="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#panelsStayOpen-${id}-collapse`}
        aria-expanded={isOpen}
        aria-controls={`panelsStayOpen-${id}-collapse`}
      >
        {title}
      </button>
    </h4>
    <div
      id={`panelsStayOpen-${id}-collapse`}
      className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
      aria-labelledby={`panelsStayOpen-${id}`}
    >
      <div className="accordion-body">{content}</div>
    </div>
  </div>
);

const ReviewBox = ({
  profileImg,
  name,
  date,
  rating,
  review,
  galleryImages,
  isPositive,
}) => (
  <div className="review-box d-md-flex">
    <div className="review-profile">
      <img src={profileImg} alt="User" />
    </div>
    <div className="review-info">
      <h6 className="mb-2 tittle">
        {name} Booked on {date}
      </h6>
      <div className="rating">
        {[...Array(5)].map((_, index) => (
          <i
            key={index}
            className={`fas fa-star ${index < rating ? "filled" : ""}`}
          ></i>
        ))}
        <span>{rating}.0</span>
      </div>
      <span className={isPositive ? "success-text" : "warning-text"}>
        <i className={isPositive ? "feather-check" : "feather-x"}></i>
        {isPositive
          ? "Yes, I would book again."
          : "No, I don't want to book again."}
      </span>
      <h6>{review.title}</h6>
      <p>{review.content}</p>
      {galleryImages && (
        <ul className="review-gallery clearfix">
          {galleryImages.map((img, index) => (
            <li key={index}>
              <a href={img.thumb} data-fancybox="gallery">
                <img className="img-fluid" alt="Image" src={img.src} />
              </a>
            </li>
          ))}
        </ul>
      )}
      <span className="post-date">Sent on {review.date}</span>
    </div>
  </div>
);

const GalleryItem = ({ src, thumb }) => (
  <a className="corner-radius-10" href={thumb} data-fancybox="gallery3">
    <img className="img-fluid corner-radius-10" alt="Image" src={src} />
  </a>
);

const Turfdetailcontent = () => {
  const overviewContent = (
    <>
      <p>
        Welcome to Exson Academy, a premier turf booking facility located in
        Sacramento, CA. We are dedicated to delivering top-notch services,
        offering a variety of amenities and equipment to support athletes in
        their training and development.
      </p>
      <p>
        Our facility is outfitted with cutting-edge features, creating an ideal
        environment for athletes to excel in their sports endeavors.
      </p>
      <p>
        Whether you're a professional athlete or a sports enthusiast, Exson
        Academy is the perfect place to hone your skills and achieve your goals.
        Contact Mart Dublin for more information and to book your next session.
      </p>
    </>
  );

  const includesContent = (
    <ul className="clearfix">
      {[
        "Badminton Racket Unlimited",
        "Bats",
        "Hitting Machines",
        "Multiple Courts",
        "Spare Players",
        "Instant Racket",
        "Green Turfs",
      ].map((item, index) => (
        <li key={index}>
          <i className="feather-check-square"></i>
          {item}
        </li>
      ))}
    </ul>
  );

  const rulesContent = (
    <ul>
      {[
        "Non Marking Shoes are recommended not mandatory for Badminton.",
        "A maximum number of members per booking per badminton court is admissible fixed by Venue Vendors",
        "No pets, no seeds, no gum, no glass, no hitting or swinging outside of the cage",
      ].map((rule, index) => (
        <li key={index}>
          <p>
            <i className="feather-alert-octagon"></i>
            {rule}
          </p>
        </li>
      ))}
    </ul>
  );

  const amenitiesContent = (
    <ul className="d-md-flex justify-content-between align-items-center">
      {["Parking", "Drinking Water", "First Aid", "Change Room", "Shower"].map(
        (amenity, index) => (
          <li key={index}>
            <i className="fa fa-check-circle" aria-hidden="true"></i>
            {amenity}
          </li>
        )
      )}
    </ul>
  );

  const galleryItems = [
    {
      src: "assets/img/gallery/gallery2/gallery-01.jpg",
      thumb: "assets/img/gallery/gallery2/gallery-thumb-01.jpg",
    },
    {
      src: "assets/img/gallery/gallery2/gallery-02.jpg",
      thumb: "assets/img/gallery/gallery2/gallery-thumb-02.jpg",
    },
    {
      src: "assets/img/gallery/gallery2/gallery-03.jpg",
      thumb: "assets/img/gallery/gallery2/gallery-thumb-03.jpg",
    },
  ];

  const reviews = [
    {
      profileImg: "assets/img/profiles/avatar-01.jpg",
      name: "Amanda",
      date: "06/04/2023",
      rating: 5,
      review: {
        title: "Absolutely perfect",
        content:
          "If you are looking for a perfect place for friendly matches with your friends or a competitive match, It is the best place.",
        date: "11/03/2023",
      },
      galleryImages: [
        {
          src: "assets/img/gallery/gallery-01.jpg",
          thumb: "assets/img/gallery/gallery-thumb-01.jpg",
        },
        {
          src: "assets/img/gallery/gallery-02.jpg",
          thumb: "assets/img/gallery/gallery-thumb-02.jpg",
        },
      ],
      isPositive: true,
    },
  ];

  return (
    <>
      <div className="content venue-coach-details">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <div className="venue-options white-bg mb-4">
                <ul className="clearfix">
                  {[
                    "overview",
                    "includes",
                    "rules",
                    "amenities",
                    "gallery",
                    "reviews",
                    "location",
                  ].map((section, index) => (
                    <li
                      key={index}
                      className={section === "overview" ? "active" : ""}
                    >
                      <a href={`#${section}`}>
                        {section.charAt(0).toUpperCase() + section.slice(1)}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="accordion" id="accordionPanel">
                <AccordionSection
                  id="overview"
                  title="Overview"
                  content={overviewContent}
                  isOpen={true}
                />
                <AccordionSection
                  id="includes"
                  title="Includes"
                  content={includesContent}
                />
                <AccordionSection
                  id="rules"
                  title="Rules"
                  content={rulesContent}
                />
                <AccordionSection
                  id="amenities"
                  title="Amenities"
                  content={amenitiesContent}
                />
                <AccordionSection
                  id="gallery"
                  title="Gallery"
                  content={
                    <div className="owl-carousel gallery-slider owl-theme">
                      {galleryItems.map((item, index) => (
                        <GalleryItem
                          key={index}
                          src={item.src}
                          thumb={item.thumb}
                        />
                      ))}
                    </div>
                  }
                />
                <AccordionSection
                  id="reviews"
                  title="Reviews"
                  content={
                    <>
                      {reviews.map((review, index) => (
                        <ReviewBox key={index} {...review} />
                      ))}
                      <div className="d-flex justify-content-center mt-1">
                        <button
                          type="button"
                          className="btn btn-load-more d-flex justify-content-center align-items-center"
                        >
                          Load More<i className="feather-plus-square"></i>
                        </button>
                      </div>
                    </>
                  }
                />
                <AccordionSection
                  id="location"
                  title="Location"
                  content={
                    <>
                      <div className="google-maps">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d393942.930343782!2d80.11976437616191!3d13.08268026035633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267e2046a177b%3A0x2c2a437d43eae6b!2sChennai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1690810729744!5m2!1sen!2sin"
                          width="600"
                          height="450"
                          style={{ border: 0 }}
                          allowFullScreen=""
                          loading="lazy"
                        ></iframe>
                      </div>
                    </>
                  }
                />
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
              <div className="venue-right-sidebar">{/* future ads  */}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Turfdetailcontent;
