import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";

const termsData = [
  {
    title: "Introduction",
    content: `Welcome to LawnLink’s turf booking website. By accessing or using our website, you agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our services.`,
  },
  {
    title: "Booking and Cancellation",
    content: `
      Booking Process: To book a turf, you must create an account and follow the booking procedures outlined on our website.
      Payment: Full payment is required at the time of booking. We accept various payment methods as listed on our website.
      Cancellation Policy: Cancellations made within 24 hours of booking will be fully refunded. Cancellations made less than 24 hours before the booked time will incur a cancellation fee as specified on our website.
    `,
  },
  {
    title: "Use of Facilities",
    content: `
      Conduct: Users are expected to conduct themselves in a manner that does not interfere with other users’ enjoyment of the facilities.
      Damage: Users will be held responsible for any damage caused to the turf or other facilities during their booking period.
      Equipment: Users must use their own equipment unless otherwise specified. LawnLink is not responsible for any personal equipment brought to the venue.
    `,
  },
  {
    title: "User Accounts",
    content: `
      Account Creation: Users must provide accurate and complete information when creating an account. You are responsible for maintaining the confidentiality of your account information.
      Termination: LawnLink reserves the right to terminate accounts at its discretion, including but not limited to, for violations of these Terms and Conditions.
    `,
  },
  {
    title: "Liability",
    content: `
      Disclaimer: LawnLink is not liable for any injuries, losses, or damages that occur during the use of our facilities.
      Insurance: Users are advised to carry their own insurance for personal injuries and property damage.
    `,
  },
  {
    title: "Privacy",
    content: `
      Data Collection: We collect personal information to facilitate bookings and improve our services. Our full Privacy Policy is available on our website.
      Data Use: Personal information will not be shared with third parties without user consent, except as required by law.
    `,
  },
  {
    title: "Modifications",
    content: `
      Terms Changes: LawnLink reserves the right to modify these Terms and Conditions at any time. Users will be notified of significant changes through our website.
      Service Changes: We may modify or discontinue services at any time without prior notice.
    `,
  },
  {
    title: "Governing Law",
    content: `These Terms and Conditions are governed by the laws of the jurisdiction in which LawnLink operates.`,
  },
];

const TermsAndConditions = () => {
  return (
    <div className="content">
      <Header />
      <div className="breadcrumb breadcrumb-list mb-0">
        <span className="primary-right-round"></span>
        <div className="container content">
          <h1 className="#">Terms</h1>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>Terms</li>
          </ul>
        </div>
      </div>

      <div className="content">
        <div className="container">
          <h3 className="text-center">
            Terms and Conditions of Use for LawnLink Turf Booking
          </h3>
          {termsData.map((section, index) => (
            <div key={index} className="terms-section">
              <h3>{section.title}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: section.content.replace(/\n/g, "<br/>"),
                }}
              ></p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
