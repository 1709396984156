import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="terms-page innerpagebg">
      <div className="content">
        <div className="container"></div>
        <div className="breadcrumb breadcrumb-list mb-0">
          <span className="primary-right-round"></span>
          <div className="container">
            <h1 className="text-white">Privacy Policy</h1>
            <ul>
              <li>
                <a href="/home">Home</a>
              </li>
              <li>Privacy Policy</li>
            </ul>
          </div>
        </div>

        <div className="content">
          <div className="container">
            <h3>Privacy Policy </h3>
            <div className="condition-details">
              <p>
                Introduction LawnLink is committed to protecting your privacy.
                This Privacy Policy explains how we collect, use, and safeguard
                your personal information when you use our turf booking
                services. Information Collection and Use We collect personal
                information from you when you book a turf through our website.
                This information may include your name, email address, phone
                number, payment details, and any other information you provide
                during the booking process. We use this information to confirm
                your bookings, process payments, and communicate with you about
                your reservations. Data Protection and Security At LawnLink, we
                implement stringent security measures to protect your personal
                information from unauthorized access, alteration, disclosure, or
                destruction. We use secure servers and encryption protocols to
                ensure that your data remains safe and confidential. Third-Party
                Sharing We do not sell, trade, or otherwise transfer your
                personal information to outside parties. However, we may share
                your information with trusted third parties who assist us in
                operating our website, conducting our business, or servicing
                you, so long as those parties agree to keep this information
                confidential. Cookies and Tracking Technologies Our website uses
                cookies and similar tracking technologies to enhance your user
                experience. Cookies are small data files stored on your device
                that help us understand how you interact with our website. You
                can choose to disable cookies through your browser settings, but
                doing so may affect the functionality of our site. User Rights
                and Choices You have the right to access, update, or delete your
                personal information at any time. If you wish to exercise any of
                these rights, please contact us using the information provided
                below. We will respond to your request promptly and ensure that
                your information is handled in accordance with applicable data
                protection laws. Changes to Our Privacy Policy LawnLink reserves
                the right to update or change this Privacy Policy at any time.
                Any changes will be posted on this page, and we encourage you to
                review this policy periodically to stay informed about how we
                are protecting your information. Contact Us If you have any
                questions or concerns about our Privacy Policy or the handling
                of your personal information, please contact us at: LawnLink
                Email: support@lawnlink.com Phone: +1 (555) 123-4567 Consent By
                using our turf booking services, you consent to the terms of
                this Privacy Policy.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default PrivacyPolicy;
