import Profileedit from "./Profileedit";
// import Changepassword from "./Changepassword";
// import Othersetting from "./Othersetting";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";

const Profileedithome = () => {
  return (
    <div>
      <Header></Header>
      <Profileedit></Profileedit>
      {/* <Changepassword></Changepassword>
      <Othersetting></Othersetting> */}
      <Footer></Footer>
    </div>
  );
};
export default Profileedithome;
