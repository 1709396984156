import Footer from "../Footer/Footer";
import Header from "../Home/Header";
// import Availableoffers from "./Availableoffers";
import Offersgrid from "./Offersgrid";

const Availableoffershome = () => {
  return (
    <div className="events-page">
      <Header></Header>
      {/* <Availableoffers></Availableoffers> */}
      <Offersgrid></Offersgrid>
      <Footer></Footer>
    </div>
  );
};
export default Availableoffershome;
