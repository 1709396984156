import React, { useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";
import "./Faq.css";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  return (
    <>
      <Header></Header>
      <div className="content new-faq">
        <div className="container">
          <div className="section-heading aos">
            <h2>
              Frequently <span>Asked Questions</span>
            </h2>
          </div>
          <div className="row">
            <div className="col-12 offset-sm-12 offset-md-1 col-md-10 col-lg-10">
              <div className="ask-questions">
                <div className="faq-info">
                  <div className="accordion" id="accordionExample">
                    {faqItems.map((item, index) => (
                      <div className="accordion-item" key={index}>
                        <h2 className="accordion-header" id={`heading${index}`}>
                          <button
                            className={`accordion-button ${
                              activeIndex === index ? "" : "collapsed"
                            }`}
                            type="button"
                            onClick={() => handleToggle(index)}
                            aria-expanded={activeIndex === index}
                            aria-controls={`collapse${index}`}
                          >
                            {item.question}
                          </button>
                        </h2>
                        <div
                          id={`collapse${index}`}
                          className={`accordion-collapse collapse ${
                            activeIndex === index ? "show" : ""
                          }`}
                          aria-labelledby={`heading${index}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="accordion-content">
                              <p>{item.answer}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

const faqItems = [
  {
    question: "How can I book a turf at LawnLink?",
    answer:
      "Booking a turf at LawnLink is easy! Use our online booking system to select your preferred time and location, or contact our customer service for assistance.",
  },
  {
    question: "What is the duration of a turf booking?",
    answer:
      "Turf bookings at LawnLink can be made in various time slots, typically ranging from 1 hour to 3 hours, depending on availability. Check our booking system for specific options.",
  },
  {
    question: "Can I rent sports equipment at LawnLink?",
    answer:
      "Yes, LawnLink offers rental services for sports equipment. You can select and reserve the equipment you need when booking your turf online or inquire about it through our customer service.",
  },
  {
    question:
      "Are there any coaching services available for sports at LawnLink?",
    answer:
      "LawnLink provides coaching services for various sports. Please check our website for available coaching packages and schedules, or contact our support team for more information.",
  },
  {
    question: "Can I participate in sports leagues or tournaments at LawnLink?",
    answer:
      "Absolutely! LawnLink hosts a range of sports leagues and tournaments. Visit our website or reach out to our customer service for details on upcoming events and how to register.",
  },
];

export default FAQ;
